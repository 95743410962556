
import {Vue, Component, Watch, Prop} from 'vue-property-decorator'
import {BlogArticle} from './types'

enum BG_VARIANTS {
    BASE = 'base',
    TRANSPARANT = 'transparant',
    BLUE = 'blue'
}


@Component
export default class BlogVignette extends Vue {


    VARIANTS = BG_VARIANTS;

    @Prop()
    variant:BG_VARIANTS;

    @Prop()
    propArticles:BlogArticle[]

    @Prop()
    tag:string;

    @Prop()
    nbCol:number;

    _dates:string[] = [];

    selectedDate:string = '';

    articles :BlogArticle[] = [];

    @Watch('created')
    async created() {
        if(this.propArticles) {
            this.articles = this.propArticles;
        } else {
            if(this.tag) {
                this.articles = this.extractArticleByTag(this.$store.getters['blog/articles'], this.tag);
            } else {
                this.articles = this.$store.getters['blog/articles'];
            }
        }
        this.articles = this.extractByArticlePublicationDate(this.articles, new Date());

        if(this.articles.length === 0) {
            this.articles = this.extractArticleByTag(this.articles, 'fallback');
        }
        this.selectedDate = this.dates[0];

    }

    extractByArticlePublicationDate(src:BlogArticle[], date:Date):BlogArticle[] {
        const ret = [];
        const currDate = date ;
        currDate.setHours(0, 0, 0, 0) ;
        for(const item of src) {
            let startPublicationDate = null ;
            let endPublicationDate = null ;
            let startArticleDate = null ;
            if (item.hasOwnProperty('date_start_publication') && item['date_start_publication'] !== "") {
                startPublicationDate = new Date(item.date_start_publication) ;
                startPublicationDate.setHours(0,0,0,0) ;
            }
            if (item.hasOwnProperty('date_end_publication') && item['date_end_publication'] !== "") {
                endPublicationDate = new Date(item.date_end_publication) ;
                endPublicationDate.setHours(0,0,0,0) ;
            }
            if (item.hasOwnProperty('date_start_article') && item['date_start_article'] !== "") {
                startArticleDate = new Date(item.date_start_article) ;
                startArticleDate.setHours(0,0,0,0) ;
            }

            let publish:boolean = false ;

            // Start and/or end publication date. Don't account for start or end article dates.
            if (startPublicationDate || endPublicationDate) {
                // With start / end boundaries
                if (startPublicationDate && endPublicationDate && startPublicationDate <= currDate && endPublicationDate >= currDate) {
                    publish = true ;
                }
                // With start only
                else if (startPublicationDate && !endPublicationDate && startPublicationDate <= currDate) {
                    publish = true ;
                }
                // With end only
                if (!startPublicationDate && endPublicationDate && endPublicationDate >= currDate) {
                    publish = true ;
                }
            }
            // No start/end publication dates. Only publish events starting today or later on
            else if(startArticleDate && startArticleDate >= currDate) {
                publish = true ;
            }

            if (this._dates && this._dates.length > 5 && item.date_start_article !== this._dates[5]) {
                publish = false ;
            }
            if (publish) {
                ret.push(item) ;
                if(!this._dates) this._dates = [] ;
                if(!this._dates.includes(item.date_start_article)) this._dates.push(item.date_start_article) ;
            }
        }
        return ret;
    }

    extractArticleByTag(src:BlogArticle[], tag:string):BlogArticle[] {
        const ret = [];
        for(const item of src) {
            if( item.tags && item.tags.includes(tag)){
                ret.push(item);
            }
        }
        return ret.length === 0 ? src : ret;
    }

    openURL(article:BlogArticle) {
        if (article.url) {
            if(article.url.includes('http://') || article.url.includes('https://')) {
                window.open(article.url, '_blank')
            } else {
                window.open('https://'+ article.url, '_blank')
            }

        }
    }

    decodeHtml(html:string) {
        if (!html)
            return ''
        const txt = document.createElement("textarea")
        txt.innerHTML = html
        return txt.value
    }

    goToDate(increment:number) {
        this.selectDate(this.dates[this.dates.indexOf(this.selectedDate) + increment])
    }

    formatDatesEvents(date:string):string {
        return date.split('-')[2] + '/' + date.split('-')[1];
    }

    selectDate(date:string) {
        this.selectedDate = date;
    }


    get articleLoaded() {
        return this.articles.length > 0
    }

    get articlesByEventDates():{[key:string] : BlogArticle[]} {
        const articlesSorted = this.articles.sort((a:BlogArticle,b:BlogArticle) => {
            return new Date(a.date_start_article).getTime() - new Date(b.date_start_article).getTime();
        });

        const ret:{[key:string] : BlogArticle[]} = {};
        for(const article of articlesSorted) {
            if(ret[article.date_start_article]) {
                ret[article.date_start_article].push(article)
            } else {
                ret[article.date_start_article] = [article];
            }
        }

        return ret;
    }

    get dates():string[] {
        if(this._dates){
            return this._dates.sort((a:string,b:string) => {
                return new Date(a).getTime() - new Date(b).getTime();
            })
        }
        return [new Date().getTime().toString()]
    }
}
