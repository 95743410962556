import { Vue, Component } from 'vue-property-decorator';

@Component({
    name: 'header-3e'
})
export default class Header3E extends Vue {
    logoLink1:string = 'https://www.3eco.com/';
    headerText1 = 'Explore all of 3E';
    headerLink1 = 'https://www.3eco.com/';
    headerText2 = 'Quick-FDS is now part of 3E';
    btnText1 = 'Learn more';
    get btnLink1() {
        return `https://www.3eco.com/${this.$lang}/article/3e-acquires-workplace-safety-provider-quick-fds/`;
    }
}