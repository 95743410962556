export const SUPPORTED_LOCALES = [
    {
        code: 'fr',
        base: '/fr',
        flag: 'fr',
        name: 'Français',
        translations: '/internationalisation/fr.json'
    }, {
        code: 'en',
        base: '',
        flag: 'us',
        name: 'English',
        translations: '/internationalisation/en.json'
    }, {
        code: 'de',
        base: '/de',
        flag: 'de',
        name: 'Deutsch',
        translations: '/internationalisation/de.json'
    }, {
        code: 'it',
        base: '/it',
        flag: 'it',
        name: 'Italian',
        translations: '/internationalisation/it.json'
    }
]

export enum LanguagesEnum {
    FR = 'fr',
    EN = 'en',
    DE = 'de',
    IT = 'it',
    NL = 'nl',
    AR = 'ar',
    BG = 'bg',
    CS = 'cs',
    DA = 'da',
    EL = 'el',
    ES = 'es',
    ET = 'et',
    FA = 'fa',
    FI = 'fi',
    HE = 'he',
    HR = 'hr',
    HU = 'hu',
    ID = 'id',
    IS = 'is',
    JA = 'ja',
    KO = 'ko',
    LT = 'lt',
    LV = 'lv',
    MS = 'ms',
    MT = 'mt',
    NO = 'no',
    PL = 'pl',
    PT = 'pt',
    RO = 'ro',
    RU = 'ru',
    SK = 'sk',
    SL = 'sl',
    SR = 'sr',
    SV = 'sv',
    TH = 'th',
    TR = 'tr',
    UK = 'uk',
    VI = 'vi',
    ZH = 'zh',
    ZT = 'zt'
}