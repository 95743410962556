import {Component,Prop, Vue, Watch} from "vue-property-decorator";

@Component({
    name: 'popin-error',
})
export default class PopinError extends Vue {

    get codeError() {
        return this.$store.getters['navigation/codeError'];
    }
    get messageError() {
        return this.$store.getters['navigation/messageError'];
    }
    get customError() {
        return this.$store.getters['navigation/customError'];
    }
    clearError() {
        this.$store.commit('navigation/clearError')
    }
}

