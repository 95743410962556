
// START SHOW
export interface Show {
    title: string,
    imageURL?: string,
    url: string,
    dateStart: string,
    dateText: string,
    localisation: string
}

export const ShowDatas = [
    {
        title : 'Préventica Nord',
        imageURL : require('@/assets/img/illus-preventica.jpg'),
        url : 'https://www.preventica.com/congres-salons-preventica-infos-pratiques.php',
        dateStart : '30/09/2021',
        dateText : '28 au 30 septembre 2021',
        localisation : 'Lille Grand Palais - Stand E06'
    },
    {
        title : 'Préventica Paris',
        imageURL : require('@/assets/img/illus-preventica.jpg'),
        url : 'https://www.preventica.com',
        dateStart : '01/12/2021',
        dateText : '29 novembre au 1er décembre 2021',
        localisation : 'Porte de Versailles'
    },
    {
        title : 'Eurocoat paris',
        imageURL : require('@/assets/img/illus-eurocoat.jpg'),
        url : 'https://www.eurocoat-expo.com/',
        dateStart : '31/03/2022',
        dateText : '29 au 31 mars 2022',
        localisation : 'Paris'
        
    },
    {
        title : 'PREVENTICA LYON EUREXPO',
        imageURL : require('@/assets/img/logo-preventica-lyon-2022.png'),
        url : 'https://www.preventica.com/visiteur-formulaire-inscription.php?salon=lyon',
        dateStart : '29/09/2022',
        dateText : 'du 27 au 29 septembre 2022',
        localisation : 'Quick-FDS sur le Stand F17'
    },
    {
        title : 'Expo protection paris',
        imageURL : require('@/assets/img/illus-expo.jpg'),
        url : 'https://www.expoprotection.com/fr-fr.html',
        dateStart : '17/11/2022',
        dateText : '15 au 17 novembre 2022',
        localisation : 'PARC DES EXPOSITIONS, Porte de Versailles - Pavillon 01'
    },
    {
        title : 'PREVENTICA PARIS',
        imageAlt : 'logo preventica',
        url : 'https://www.preventica.com/visiteur-formulaire-inscription.php?salon=paris',
        dateStart : '25/05/2023',
        dateText : 'Du 23 au 25 mai 2023',
        localisation : 'PORTE DE VERSAILLES - HALL 3 | Quick-FDS vous accueille sur le Stand E19'
    },
    {
        title : 'PREVENTICA TOULOUSE',
        imageAlt : 'logo preventica',
        url : 'https://www.preventica.com/visiteur-formulaire-inscription.php?salon=toulouse',
        dateStart : '21/09/2023',
        dateText : 'Du 19 au 21 septembre 2023',
        localisation : 'MEETT PARC DES EXPOSITIONS | Quick-FDS vous accueille sur le Stand D28'
    },
]
// END SHOW