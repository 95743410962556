import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators' ;
import { i18n } from '@/internationalisation/i18n';
import store from '@/store' ;
import API from "@/api/wrapper";
import {Catalog_GET_APIClass} from "@/api/generated/classes";
import ErrorService from '@/services/ErrorService';


@Module({
  namespaced: true
})
export default class ToolsStore extends VuexModule {

    _tools:any = {
        "FDS_DATA_EXTRACT": {
            url: "fds-extract",
            icon: "far fa-file-excel",
            text: "list_tools.paid.fds_extract"
        },
        "VIGI_SUBSTANCES": {
            url: "vigi-substances",
            icon: "lnr lnr-binoculars",
            text: "list_tools.paid.vigi_substance"
        },
        "fdsRetriever": {
            url: "fds-retriever",
            icon: "fas fa-globe-africa",
            text: "list_tools.coming_soon.fds_retriever"
        },
        "quickIcpe": {
            url: "quick-icpe",
            img: "picto-icpe",
            text: "list_tools.coming_soon.quick_icpe"
        },
        "quickAdr": {
            url: "quick-adr",
            icon: "lnr lnr-truck",
            text: "list_tools.coming_soon.quick_adr"
        },
        fdsLabels: {
            url: "fds-labels",
            img: "picto-etiquette",
            text: "list_tools.fds_labels"
        }
    };
    _tools_old:any = {
        free: {
            "packDiscover": {
                url: "free-pack",
                icon: "fas fa-gift",
                text: "list_tools.free.discover"
            }
        },
        paid: {
            "FDS_DATA_EXTRACT": {
                url: "fds-extract",
                icon: "far fa-file-excel",
                text: "list_tools.paid.fds_extract"
            },
            "VIGI_SUBSTANCES": {
                url: "vigi-substances",
                icon: "lnr lnr-binoculars",
                text: "list_tools.paid.vigi_substance"
            }
        },
        comingSoon: {
            "fdsRetriever": {
                url: "fds-retriever",
                icon: "fas fa-globe-africa",
                text: "list_tools.coming_soon.fds_retriever"
            },
            "quickIcpe": {
                url: "quick-icpe",
                icon: "picto-icpe",
                text: "list_tools.coming_soon.quick_icpe"
            },
            "quickAdr": {
                url: "quick-adr",
                icon: "lnr lnr-truck",
                text: "list_tools.coming_soon.quick_adr"
            }
        }
    }
    _catalog: any = {};
    _catalogReady: boolean = false;


    get catalogReady() {
        return this._catalogReady;
    }
    get tools() {
        return this._tools;
    }
    get tools_old() {
        return this._tools_old;
    }
    get catalog() {
        return this._catalog;
    }
    get catalogOffers() {
        let obj:any = [];
        for (const i in this._catalog) {
            if (this._catalog.hasOwnProperty(i)) {
                obj = obj.length ? obj.concat(this._catalog[i].offers) : this._catalog[i].offers;
            }
        }
        return obj;
    }
    get toolIdByOfferId() {
        const offers:any = {};
        for (const item of this._catalog) {
            for (const offer of item.offers) {
                offers[offer.id] = item.id
            }
        }
        return offers;
    }
    get offersIdsByToolId() {
        const tools:any = {};
        for (const item of this._catalog) {
            tools[item.id] = [];
            for (const offer of item.offers) {
                tools[item.id].push(offer.id)
            }
        }
        return tools;
    }
    get offersIds() {
        const offers:number[] = [];
        for (const item of this._catalog) {
            for (const offer of item.offers) {
                offers.push(offer.id)
            }
        }
        return offers;
    }
    get offersTechnicalReferences() {
        const offers:number[] = [];
        for (const item of this._catalog) {
            for (const offer of item.offers) {
                offers.push(offer.technical_reference)
            }
        }
        return offers;
    }
    get offersReferences() {
        const offers:number[] = [];
        for (const item of this._catalog) {
            for (const offer of item.offers) {
                offers.push(offer.reference)
            }
        }
        return offers;
    }
    get offerNameByOfferId() {
        const offers:any = {};
        for (const item of this._catalog) {
            for (const offer of item.offers) {
                offers[offer.id] = offer.name
            }
        }
        return offers;
    }
    get offerPriceByOfferId() {
        const prices:any = {};
        for (const item of this._catalog) {
            for (const offer of item.offers) {
                prices[offer.id] = offer.price
            }
        }
        return prices;
    }
    get offerIdFromOfferTechnicalReference() {
        const offers:any = {};
        for (const item of this._catalog) {
            for (const offer of item.offers) {
                offers[offer.technical_reference] = offer.id
            }
        }
        return offers;
    }
    get offerTechnicalReferenceFromOfferId() {
        const offers:any = {};
        for (const item of this._catalog) {
            for (const offer of item.offers) {
                offers[offer.id] = offer.technical_reference
            }
        }
        return offers;
    }
    get offerReferenceFromOfferId() {
        const offers:any = {};
        for (const item of this._catalog) {
            for (const offer of item.offers) {
                offers[offer.id] = offer.reference
            }
        }
        return offers;
    }
    @Mutation
    addToCatalog(obj:any) {
        // maj de l'id interne
        this._tools_old.paid[obj.tool_reference].id = obj.id
        this._catalog[obj.tool_reference] = obj;
    }
    @Mutation
    setReady(ready:boolean) {
        this._catalogReady = ready;
    }

    @Action
    setCatalog() {   
        const APIClass: Catalog_GET_APIClass = new Catalog_GET_APIClass();
        API.shop.getCatalog(
            APIClass,
            (response:any) => {
                // boucle dans les outils
                for (const item of response.data.collection.items) {
                    const obj: any = {
                        offers: []
                    };
                    // boucle pour associer les paires clé/valeur
                    for (const data of item.data) {
                        obj[data.name] = data.value;
                    }
                    // boucle pour associer les paires clé/valeur dans "offers"
                    for (const subitem of item.items) {
                        const offer: any = {};
                        for (const subdata of subitem.data) {
                            offer[subdata.name] = subdata.value;
                        }
                        obj.offers.push(offer);
                    }
                    this.context.commit('addToCatalog', obj);
                }
                this.context.commit('setReady', true);
            },
            (err:any) => {
                ErrorService.onDefaultError(err);
            }
        )
    }
}
