import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators' ;
import Store from '@/store' ;
import API from "@/api/wrapper";
import {
    Cart_PUT_APIInterface,
    Cart_PUT_APIQueryInterface,
    Users_Login_Command_DELETE_APIQueryInterface,
    Users_Login_Command_POST_APIQueryInterface,
    Users_Login_Command_GET_APIQueryInterface
} from "@/api/generated/types";
import {
    Cart_PUT_APIClass,
    Cart_GET_APIClass,
    Cart_POST_APIClass,
    Users_Login_Command_DELETE_APIClass,
    Users_Login_Command_POST_APIClass,
    Users_Login_Command_GET_APIClass
} from "@/api/generated/classes";
import {CART, OFFER} from "@/types/ShoppingState";
import APIClass from '@/api/APIClass';
import ErrorService from '@/services/ErrorService';

// return the basket saved in the localStorage
function storageCart():CART | undefined {
    if(typeof localStorage.getItem('cart') === 'string') {
        return JSON.parse(localStorage.getItem('cart') || "{}") as CART;
    } else {
        return undefined;
    }
}
@Module({
    namespaced: true
})
export default class ShoppingState extends VuexModule {

    _cart: CART = {
        offers: [],
        quantity: 1,
        minimum_quantity: 1,
        command: null,
        step: 0
    }
    _offersWaitingForPayment: OFFER[] = []
    _pkClientSecret:string = '';
    _cartReady: boolean = false;
    _error: number|null = null;

    /* ACCESSEURS */
    get pk(): string {
        return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    }
    get pkClientSecret(): string {
        return this._pkClientSecret;
    }
    get offersWaitingForPayment(): OFFER[] {
        return this._offersWaitingForPayment;
    }
    get cart(): CART {
        return this._cart;
    }
    get cartReady(): boolean {
        return this._cartReady;
    }
    get cartIsNotEmpty(): boolean {
        return this._cart.offers.length > 0;
    }
    get cartStep(): number {
        return this._cart.step;
    }
    get cartOffersId(): number[] {
        return this._cart.offers.length ? this._cart.offers.map(offer => offer.offer_id): [];
    }
    get cartOffersQuantity(): number[] {
        return this._cart.offers.map(offer => offer.quantity || 0);
    }
    get cartOffersEtPrice(): number[] {
        return this._cart.offers.map(offer => offer.et_price || 0);
    }
    get cartOffersItPrice(): number[] {
        return this._cart.offers.map(offer => offer.it_price || 0);
    }
    get cartQuantity(): number {
        return this._cart.quantity;
    }
    get cartMinimumquantity(): number {
        return this._cart.minimum_quantity;
    }
    get cartEtPrice(): number {
        return this.cartOffersEtPrice.reduce((a,b)=>a+b, 0)
    }
    get cartItPrice(): number {
        return this.cartOffersItPrice.reduce((a,b)=>a+b, 0)
    }
    get cartWaitingForPayment(): boolean {
        return !!this._cart.command;
    }
    get offersIdsByToolId() {
        const toolIdByOfferId = Store.getters['tools/toolIdByOfferId']
        const response:any= {};
        this.cartOffersId.forEach((id:number) => {
            if (toolIdByOfferId.hasOwnProperty(id)) {
                const idTool:number = toolIdByOfferId[id];
                if (!response.hasOwnProperty(idTool)) {
                    response[idTool] = [];
                }
                response[idTool].push(id)
            }
        })
        return response;
    }
    get error() {
        return this._error;
    }

    /* MUTATIONS */
    @Mutation
    init() {
        /*
        const localCart = storageCart();
        if(localCart) {
            this._cart = {
                ...this._cart,
                ...localCart
            }
        }
        */
    }
    @Mutation
    setReady(ready:boolean) {
        this._cartReady = ready;
    }
    @Mutation
    clearCart() {
        this._cart = {
            offers: [],
            quantity: 1,
            minimum_quantity: 1,
            step: 0
        }
    }
    @Mutation
    addOffer(id:number) {
        const offer:OFFER = {offer_id: id}
        this._cart.offers.push(offer);
    }
    @Mutation
    removeOffer(id:number) {
        const offersId = this._cart.offers.map(offer => offer.offer_id);
        this._cart.offers.splice(offersId.indexOf(id), 1);
    }
    @Mutation
    setStep(step:number) {
        this._cart.step = step;
    }
    @Mutation
    setQuantity(q:number) {
        if(q >= this._cart.minimum_quantity) {
            this._cart.quantity = q;
        } else {
            this._cart.quantity = this._cart.minimum_quantity;
        }
    }
    @Mutation
    setMinimumQuantity(q:number) {
        this._cart.minimum_quantity = q;
    }
    @Mutation
    setCartOffer(payload:{i:number, offer: OFFER}) {
        const {i,offer} = payload;
        if (i > -1) {
            this._cart.offers[i] = offer;
        } else {
            this._cart.offers.push(offer);
        }
    }
    @Mutation
    setOffersWaitingForPayment(offers:OFFER[]) {
        this._offersWaitingForPayment.length = 0;
        for (const offer of offers) {
            this._offersWaitingForPayment.push(offer);
        }
    }
    @Mutation
    updateCartGeneralInfo(infos:(number|string|boolean|null)[]) {
        this._cart = {
            ...this._cart,
            ...infos
        };
    }
    @Mutation
    setPkClientSecret(pk:string) {
        this._pkClientSecret = pk;
    }
    @Mutation
    setError(error:number) {
        this._error = error;
    }

    
    
    /* ACTIONS */
    @Action
    clearPending() {
        this.context.commit("clearCart");
        this.context.commit("setOffersWaitingForPayment", []);
        Store.commit("account/setSubscriptionPending", false);
    }
    @Action
    updateCartOffer(offer:OFFER) {
        let index = -1;
        if (this._cart.offers.length) {
            index = this.cartOffersId.indexOf(offer.offer_id);
            if (index > -1) {
                offer = {
                    ...this.context.getters['cart'].offers[index],
                    ...offer
                };
            }
        }
        this.context.commit('setCartOffer', {index, offer})
    }
    @Action
    goToStep(payload:{step:number|string, callback:Function}) {
        const {step, callback} = payload;
        let nextStep:number = this.context.getters['cartStep'];
        if (typeof step === 'string' && step === '+') {
            nextStep++;
        } else if (typeof step === 'string' && step === '-') {
            nextStep--;
        } else if (typeof step === 'number') {
            nextStep = step;
        }
        switch (nextStep) {
            case 0:
                this.context.commit('setStep', nextStep);
                break;
            case 1:
                this.context.dispatch('putCart');
                this.context.commit('setQuantity', this.context.getters['cartQuantity']);
                this.context.commit('setStep', nextStep);
                break;
            case 2:
                if (Store.getters['account/isLoggedIn']) {
                    this.context.dispatch('putCart')
                    .then(()=>{
                        this.context.commit('setStep', nextStep)
                    })
                    .catch((err:any)=>{
                    })
                    
                } else {
                    callback(step)
                }
                break;
            case 3:
                this.context.dispatch('postCart');
                this.context.commit('setStep', nextStep);
                break;
            case 4:
                this.context.commit('setStep', nextStep);
                break;
            default:
                break;
        }
    }

    @Action
    updateMinimumQuantity(quantity:number) {
        // make sure that quantity is always superior to minimum
        if (this.context.getters['cartQuantity'] < quantity) {
            // if not, set quantity to minimum
            this.context.commit('setQuantity', quantity);
            // update the cart
            this.context.dispatch('putCart');
        }
        if(Store.getters['account/subscription'].tokens > 0) {
            this.context.commit('setMinimumQuantity', Math.max(Store.getters['account/subscription'].tokens / 10, quantity));
        } else {
            this.context.commit('setMinimumQuantity', quantity);
        }
    }
    @Action
    removeOfferFromCart(id:number) {
        
        // Remise à 1 de la quantité minimum dans le cas des outils FDS_Data_Extract
        const indexId = this.context.getters["cartOffersId"].indexOf(id)
        if(this.context.getters["cart"].offers[indexId].tool_label === "FDS Data Extract") {
            this.context.commit("setMinimumQuantity", 1);
        }
        this.context.commit('removeOffer', id);
        this.context.dispatch('putCart');
    }
    @Action
    updateCart(offersId: number[]) {   
        // if cart is not empty
        if(this.context.getters['cartOffersId'].length>0) {
            // for each id in cart that is not in the array, we remove it
            this.context.getters['cartOffersId'].map((cartOfferId:number)=> {
                if (!offersId.includes(cartOfferId)) {
                    this.context.commit('removeOffer', cartOfferId);
                }
            })
            // for each id in array that is not in cart, we add it
            offersId.map((offerId:number) => {
                if (!this.context.getters['cartOffersId'].includes(offerId)) {
                    this.context.commit('addOffer', offerId);
                }
            })
        } else {
            offersId.map((offerId:number) => {
                this.context.commit('addOffer', offerId)
            });
        }
        // if user is still in step 0, we move him up to step 1
        if (this.context.getters['cartStep'] === 0){
            this.context.commit('setStep', 1)
        }
        // update cart at the end
        this.context.dispatch('putCart');
    }
    @Action
    postCart() {
        const APIClass = new Cart_POST_APIClass();
        API.shop.postCart(
            APIClass,
            (res:any) => {
                this.context.commit('setPkClientSecret', res.data.collection.items[0].data[0].value);
            },
            (err:any) => {
                ErrorService.onDefaultError(err);
            }
        )
    }

    @Action
    getCart() {
        this.context.commit('setReady', false);
        this.context.commit('setError', null)
        const class_API:Cart_GET_APIClass = new Cart_GET_APIClass();
        
        API.shop.getCart(
            class_API,
            (res:any) => {
                const infos:any = {offers: []};
                for (const data of res.data.collection.items[0].data) {
                    infos[data.name] = data.value;
                }
                this.context.commit('updateCartGeneralInfo', infos);
                if (res.data.collection.items[0].items) {
                    for (const item of res.data.collection.items[0].items) {
                        const offer:any = {};
                        for (const data of item.data) {
                            offer[data.name] = data.value;
                        }
                        this.context.dispatch('updateCartOffer', offer);
                    }
                    this.context.dispatch('putCart');
                }
            },
            (err:any) => {
                ErrorService.onDefaultError(err);
                this.context.commit('setReady', true);
            }
        )
    }

    @Action
    putCart() {
        if ((Store.getters['account/hasSubscription']) || this.cart.offers.length > 0) {
            this.context.commit('setReady', false);
            this.context.commit('setError', null)

            const query:Cart_PUT_APIQueryInterface = {};
            const params:Cart_PUT_APIInterface = {
                offer_ids: this.cartOffersId,
                quantity: this.cartQuantity
            };
            const class_API:Cart_PUT_APIClass = new Cart_PUT_APIClass(query, params);

            return API.shop.putCart(
                class_API,
                (res:any) => {
                    const infos:any = {offers: []};
                    for (const data of res.data.collection.items[0].data) {
                        infos[data.name] = data.value;
                    }
                    this.context.commit('updateCartGeneralInfo', infos);
                    const items = res.data.collection.items[0].items;
                    if (items) {
                        for (const item of items) {
                            const offer:any = {};
                            for (const data of item.data) {
                                offer[data.name] = data.value;
                            }
                            this.context.dispatch('updateCartOffer', offer);
                        }
                    }
                    this.context.commit('setReady', true);
                },
                (err:any) => {
                    const error = err.response.data.collection.errors[0];
                    if (!!error) {
                        if (error.code === 590) {
                            const q:number = error.data[0].value;
                            if (q > this.context.getters['cartQuantity']) {
                                this.context.dispatch('updateMinimumQuantity', q);
                            }
                        } else if (error.code === 582) {
                            this.context.commit('removeOffer', error.data[0].value)
                            this.context.commit('setError', error.code)
                            //this.context.dispatch('getCart');
                        } else {
                            ErrorService.onDefaultError(err);
                        }
                    }
                    this.context.commit('setReady', true);
                }
            )
        } else {
            return false
        }
    }

    @Action
    cancelPayment() {
        return new Promise((resolve, reject) => {
            const query:Users_Login_Command_DELETE_APIQueryInterface = {
                login: Store.getters['account/profil'].login,
            };
            const class_API:Users_Login_Command_DELETE_APIClass = new Users_Login_Command_DELETE_APIClass(query);
            const success = (res:any) => {
                console.log("La commande a été supprimée avec succès");
                resolve(res);
            };
            const fail = (err:any) => {
                ErrorService.onDefaultError(err);
                reject(err);
            };
            API.user.deleteCommand(class_API, success, fail)
        })
    }
    @Action
    resumePayment() {
        return new Promise((resolve, reject) => {
            const query:Users_Login_Command_POST_APIQueryInterface = {
                login: Store.getters['account/profil'].login,
            };
            const class_API:Users_Login_Command_POST_APIClass = new Users_Login_Command_POST_APIClass(query);
            const success = (res:any) => {
                if (res.data.collection.items[0].data[0].name === 'clientsecret') {
                    this.context.commit('setPkClientSecret', res.data.collection.items[0].data[0].value);
                    resolve(res);
                } else {
                    reject(res);
                }
            };
            const fail = (err:any) => {
                ErrorService.onDefaultError(err);
                reject(err);
            };
            API.user.resumeCommand(class_API, success, fail)
        })
    }
    @Action
    getInfoCommand() {
        this.context.commit('setReady', false);
        return new Promise((resolve, reject) => {
            const query:Users_Login_Command_GET_APIQueryInterface = { 
                login: Store.getters['account/profil'].login,
            }
            const class_API:Users_Login_Command_GET_APIClass = new Users_Login_Command_GET_APIClass(query);
            const success = (res:any) => {
                const items = res.data.collection.items[0].items;
                const offers:OFFER[] = [];
                if (items) {
                    for (const item of items) {
                        const offer:any = {};
                        for (const data of item.data) {
                            offer[data.name] = data.value;
                        }
                        offers.push(offer);
                    }
                }
                this.context.commit('setOffersWaitingForPayment', offers);
                this.context.commit('setReady', true);
                resolve(offers)
            }
            const fail = (err:any) => {
                ErrorService.onDefaultError(err);
                this.context.commit('setReady', true);
                reject(err);
            };
            API.user.getInfoCommand(class_API, success, fail)
        })
    }
}
