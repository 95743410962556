import {Menu} from './types'
import LanguageSwitcher from '@/components/LanguageSwitcher/component.vue'
import Header3E from '@/components/Header3E/component.vue'

import {Vue, Component, Watch} from 'vue-property-decorator'
import API from "@/api/wrapper";
import {Users_Admin_GET_APIQueryInterface} from "@/api/generated/types";
import {Users_Admin_GET_APIClass} from "@/api/generated/classes";
import ErrorService from '@/services/ErrorService';

@Component({
    name: 'site-navigation',
    components: {
        LanguageSwitcher,
        Header3E
    }
})
export default class SiteNavigation extends Vue {

    showMiniMenu = false; // conditionne l'affichage du menu pour mobile

    isMenuTimeout:boolean = false;
    timeoutTime:number = 100;//ms

    expandedMiniMenusList:Menu[] = [];
    currentResponsiveMenuKey:string = '';
    previousScrollPosition:number;
    scrollDirection:number = 1; //0 - down, 1 - up

    get currentScrollDirection():number {
        return this.scrollDirection;
    }

    get displayPreviewInLogo() {
        return location.pathname.slice(3).startsWith('/fds-preview');
    }
    // This returns an array of the menus used to access this page.
    get currentMenuTree():Menu[] {
        return this.$store.getters['menus/currentMenuTree'] ;
    }
    // This does not need an explicit watch on route change. It will be re-evaluated automagically because it uses this.$route !!!
    get currentMainMenu():Menu|false {
        if (this.currentMenuTree && this.currentMenuTree.length) {
            return this.currentMenuTree[0] ;
        } else {
            return false;
        }
    }
    get currentPath():string|undefined {
        if (this.currentMainMenu){
            return this.$store.getters['menus/menuItemForCurrentPath'].path;
        }
    }
    get filteredMenus():Menu[] {
        return this.$store.getters['menus/filteredMenus'] ;
    }
    get filteredExternalMenus():Menu[] {
        return this.$store.getters['menus/filteredExternalMenus'] ;
    }
    get accountIsLoading () {
        return this.$store.getters['account/loading'];
    }
    get isAdmin() {
        return this.$store.getters['account/isAdmin']
    }
    get adminURL() {
        return this.$store.getters['account/adminURL']
    }
    get cartIsNotEmpty() {
        return this.$store.getters['shopping/cartIsNotEmpty'];
    }
    get displayName() {
        return this.$store.getters['account/profil'].first_name + ' ' + this.$store.getters['account/profil'].last_name.toUpperCase();
    }
    get initials() {
        const first_name = this.$store.getters['account/profil'].first_name;
        const last_name = this.$store.getters['account/profil'].last_name;
        return ((first_name.length ? first_name[0] : '') + (last_name.length ? last_name[0] : '')).toUpperCase();
    }

    @Watch('mounted')
    mounted() {
        document.addEventListener('scroll', this.checkScrollDirection, { passive: true })
        this.expandedMiniMenusList = [...this.filteredExternalMenus, ...this.filteredMenus];
    }

    /**
     * No scroll while the mini menu is up !
     */
    @Watch('showMiniMenu')
    onShowMiniMenu(show:boolean) {
        if (show) {
            document.documentElement.style.overflowY = 'hidden' 
        } else {
            document.documentElement.style.removeProperty('overflow-y');
        }
    }

    checkScrollDirection() {
        const currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
        if (currentScrollPosition > this.previousScrollPosition) { 
          //scrolled down
          this.scrollDirection = 0;
        } else if (currentScrollPosition < this.previousScrollPosition) { 
          //scrolled up
          this.scrollDirection = 1;
        }
        
        this.previousScrollPosition = currentScrollPosition;
    };

    goto(path:string) {
        /* don't go if already there */
        if (this.$store.getters['menu/currentFullPath'] !== path) {
            if (this.currentPath === '/search/') {
                this.$store.commit('search/initSearch');
            }
            this.toggleMenuTimeout();
            this.hideMenu() ;
            // @ts-ignore
            this.$refs['choose-contact']?.hide()
            this.expandedMiniMenusList.length = 0 ;
            this.$router.push(path);
        }
    }
    responsiveGoto(menu:Menu) {
        if (menu.subMenus) {
            this.currentResponsiveMenuKey = menu.key;
            this.expandedMiniMenusList = menu.subMenus;
        } else if (menu.path?.length) {
            if (menu.path.includes("/solutions/")) {
                document.location = menu.path;
            } else {
                this.$router.push(menu.path);
                this.hideMenu();
                this.expandedMiniMenusList = [...this.filteredExternalMenus, ...this.filteredMenus];
            }
        }
    }
    responsiveBack() {
        const menu = this.parentMenuFromKey([...this.filteredExternalMenus, ...this.filteredMenus], this.currentResponsiveMenuKey)
        if (menu) {
            this.expandedMiniMenusList = menu;
        }
    }
    parentMenuFromKey(menus:Menu[], key:string):Menu[]|null {
        if (menus.some(menu=>menu.key === key)) {
            this.currentResponsiveMenuKey = '';
            return menus
        } else {
            for (const menu of menus) {
                if (menu.subMenus) {
                    const result:Menu[]|null = this.parentMenuFromKey(menu.subMenus, key)
                    if (result) {
                        this.currentResponsiveMenuKey = menu.key;
                        return result;
                    }
                }
            }
        }
        return null
    }

    chooseContactType() {
        // @ts-ignore
        this.$refs['choose-contact']?.show()
    }

    goToCart() {
        if (this.$store.getters['account/hasSubscriptionPending']) {
            this.$router.push('/products_old/resume_payment')
        } else {
            this.$store.commit('shopping/setStep', 1);
            this.$router.push('/products_old')
        }
    }

    openAdmin() {
        let varUrl = this.adminURL;
        if (this.adminURL.startsWith('/api')) {
            varUrl = this.adminURL.replace('/api/', '/')
        }

        const query:Users_Admin_GET_APIQueryInterface = {
            adminUrl: varUrl
        };
        const class_API:Users_Admin_GET_APIClass = new Users_Admin_GET_APIClass(query);

        const success = (res: any) => {
            const url = res.data.collection.items[0].href;
            window.open(url, '_blank');
        }
        const fail = (err: any) => {
            if (err.response.data.collection.errors[0].code === 562) {
                this.$router.push("/logout");
            }
            ErrorService.onDefaultError(err);
        }
        API.user.getAdmin(class_API, success, fail);
    }

    toggleMenuTimeout() {
        this.isMenuTimeout = true;
        setTimeout(() => {
            this.isMenuTimeout = false;
        },this.timeoutTime)
    }

    addActiveClass(target: any) {
        target = this.$refs[target];
        if (target.length === 1) {
            target[0].classList.add('active');
        } else {
            console.error('multiple elements with same ref', this.$refs);
        }
    }
    subMenuHasCols(menu:any) {
        return menu.subMenus.some((submenu:any) => submenu.subMenus)
    }

    get currentExpandedMiniMenusList() {
        return this.expandedMiniMenusList;
    }


    hideMenu() {
        this.expandedMiniMenusList = [...this.filteredExternalMenus, ...this.filteredMenus];
        this.showMiniMenu = false ;
    }

}
