import API from '@/api/wrapper'
import {ErrorParams} from '@/api/paramsChecker'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {FreeConsultData, LoginData, Popup, RegisterData} from '@/components/popins/types'
import {
    Session_POST_APIClass,
    Session_PUT_APIClass,
    Users_Creation_request_POST_APIClass
} from "@/api/generated/classes";
import {ENUM_PROFILE_TYPE} from "@/api/generated/enums";
import {Users_Admin_GET_APIQueryInterface} from "@/api/generated/types";
import {Users_Admin_GET_APIClass} from "@/api/generated/classes";
import ErrorService from '@/services/ErrorService';
import APIClass from '@/api/APIClass';

@Component({
    name: 'popin-login'
})
export default class PopinLogin extends Vue {
    @Prop() default: string | undefined;
    @Prop() free_consult: boolean | undefined;
    @Prop() registerIsLocked?: boolean
    @Prop() freeConsultIsLocked?: boolean
    wait_for_api = false;  // true when waiting for the api confirmation
    login_fail = false;
    is_registered = false;
    qsds_not_consent = { // use for negative question in 'select' wich will be used for qsds_consent
        register: false,
        free_consult: false
    };
    
    login_data: LoginData = {
        interface : {
            login: '',
            password: '',
        },
        show : true,
        alreadyEnter : false,
        class_API : new Session_POST_APIClass()
    }
    
    errorReturnAPI:{[key:string]: string} = {register:'', login : '', free_consult:''};

    register_data: RegisterData = {
        interface : {
            last_name : '',
            first_name : '',
            company_name : '',
            email : '',
            want_safebox : false,
            profile_type : ENUM_PROFILE_TYPE.MYSDS,
            qsds_consent : true,
            weka_consent : false,
        },
        show : false,
        alreadyEnter : false,
        class_API : new Users_Creation_request_POST_APIClass()
    }

    free_consult_data: FreeConsultData = {
        interface : {
            last_name : '',
            first_name : '',
            company_name : '',
            email : '',
            qsds_consent : true,
            weka_consent : false,
        },
        show : false,
        alreadyEnter : false,
        class_API : new Session_PUT_APIClass()
    }

    
    @Watch('created')
    created() {
        switch(this.default){
            case 'login':
                this.openLogin();
                break;

            case 'register':
                this.openRegister();
                break;
            default:
                break;
        }
    }


    goNextInput(event:any) {
        const sibilings = event.target.parentNode.getElementsByTagName('input');
        let getNext = false;
        for(const sibiling of sibilings) {
            if(getNext) {
                sibiling.focus();
                break;
            }
            if(event.target === sibiling) {
                getNext = true;
            }
        }
    }

    validatePopup(event:any) {
        event.preventDefault()
        if (this.login_data.show)
            this.login()
        else if (this.register_data.show)
            this.register()
        else if (this.free_consult_data.show)
            this.freeConsult()
    }


    isValid(type:string) {
        const data: any = this.popupData;
        return data.alreadyEnter && ((data.class_API.errorsParams[type] && data.class_API.errorsParams[type].hasError) || this.errorReturnAPI.free_consult === type) ? false : undefined;
    }

    hidePopinAndGoTo(route:string) {
        this.$emit('hide');
        this.$router.push(route);
    }

    // LOGIN
    checkLoginParams() {
        if(this.login_data.alreadyEnter) {
            this.login_data.class_API.params = this.login_data.interface;
            this.login_data.class_API.checkRules();
        }
    }
    login() {
        // Generate a fake submit on the login form
        // This is mandatory for some browsers to offer the storage of the credentials
        const doc:Document|null = this.$el.ownerDocument ;
        if (doc !== null) {
            const res:HTMLFormElement|null = doc.querySelector('form#loginForm') ;
            if (res !== null) {
                res.submit() ;
            }
        }

        this.login_data.alreadyEnter = true;
        this.checkLoginParams();
        this.wait_for_api = true;
        if (!this.login_data.class_API.hasError) { // if there is no error
            API.session.login( // POST/session
                this.login_data.class_API,
                this.loginSuccess,
                this.loginFail
            );
        } else {
            this.wait_for_api = false;
        }
    }
    loginSuccess(response: any) {
        this.login_fail = false;
        this.errorReturnAPI.login = '';
        let login:string = '';
        for (const data of response.data.collection.items[0].data) {
            if (data.name === "login") {
                login = data.value;
                break;
            }
        }
        this.$store.dispatch('account/login', {login: login}).then (() => {
            // if cart has already an offer
            if (this.$store.getters['shopping/cartOffersId'].length) {
                this.$store.dispatch('shopping/updateCart');
            // if cart is empty
            } else {
                this.$store.dispatch('shopping/getCart');
            }
            // vérification des consentements dans cookieBot
            if (window.hasOwnProperty('CookieConsent')) {
                if ((<any>window).CookieConsent.consent.statistics) {
                    // @ts-ignore
                    this.$sendEventToMatomo('Login', 'Success');
                }
            }
            this.$emit('login')

            if (!this.isAdmin || this.free_consult) {
                this.$bvModal.hide('popin-login');
            }
            this.wait_for_api = false;
        });
    }

    loginFail(err: any) {
        this.wait_for_api = false;
        this.login_fail = true

        // vérification des consentements dans cookieBot
        if (window.hasOwnProperty('CookieConsent')) {
            if ((<any>window).CookieConsent.consent.statistics) {
                // @ts-ignore
                this.$sendEventToMatomo('Login', 'Fail');
            }
        }

        if (!ErrorService.onFieldError(err, this.login_data.interface, this.login_data.class_API.errorsParams)) {
            const codeError = ErrorService.getCodeError(err);

            if (codeError === 555) {
                this.errorReturnAPI.login = "wrong_login";
                console.log(this.errorReturnAPI.login)
            } else if (codeError) {
                this.errorReturnAPI.login = ErrorService.getMessageError(err) as string;
            } else if (err.custom.messageKey) { // Does it still exist ?
                this.errorReturnAPI.login = err.custom.messageKey;
            } else {
                ErrorService.onDefaultError(err);
            }
        }
    }
    // /LOGIN

    togglePassword(targetId:string, buttonId:string) {
        const password = document.querySelector('#'+targetId);
        const togglePassword = document.querySelector('#'+buttonId);
        if (password) {
            const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
            password.setAttribute('type', type)
            togglePassword?.classList.toggle('fa-eye-slash');
        }
    }

    openAdmin() {
        //window.open(this.adminURL,'_blank')
        let varUrl = this.adminURL;
        if(this.adminURL.startsWith('/api')) {
            varUrl = this.adminURL.replace('/api/', '/')
        }
        
        const query:Users_Admin_GET_APIQueryInterface = {
            adminUrl: varUrl
        };
        const class_API:Users_Admin_GET_APIClass = new Users_Admin_GET_APIClass(query);
        API.user.getAdmin(class_API, this.modifySuccess, this.modifyFail);
        this.$bvModal.hide('popin-login');
    }
    modifySuccess(res:any) {
        const url = res.data.collection.items[0].href
        window.open(url, '_blank')
    }
    modifyFail(err: any) {
        if (err.response.data.collection.errors[0].code === 562) {
            this.$router.push("/logout");
            this.$bvToast.toast(err.response.data.collection.errors[0].message, {variant: 'error'});
        }
        this.$root.$bvToast.toast(err.response.data.collection.errors[0].message, {variant: 'error'});
        
    }
    // REGISTER
    checkRegisterParams() {
        if(this.register_data.alreadyEnter) {
            this.register_data.interface.qsds_consent = !this.qsds_not_consent.register; // negative question in document : true means false
            this.register_data.class_API.params = this.register_data.interface;
            this.register_data.class_API.sendEmpty = { qsds_consent:true, weka_consent:true };
            this.register_data.class_API.checkRules();
        }
    }
    register() {
        this.register_data.alreadyEnter = true;
        this.errorReturnAPI.register = '';
        this.checkRegisterParams();
        this.wait_for_api = true;
        if (!this.register_data.class_API.hasError) { // if there is no error
            API.user.createRequest(
                this.register_data.class_API,
                this.registerSuccess,
                this.registerFail
            );
        } else {
            this.wait_for_api = false;
        }
    }

    registerSuccess(response: any) {
        let mail = "";
        if(this.register_data.class_API.params) {
            mail = this.register_data.class_API.params.email;
        }

        this.$bvModal.show('popin-register-confirmation');
        this.is_registered = true;
        this.free_consult = false;
    }
    registerFail(err: any) {
        this.wait_for_api = false;
        if(err.response && err.response.data && err.response.data.collection && err.response.data.collection.errors) {
            this.errorReturnAPI.register = err.response.data.collection.errors[0].message;
            let specificError = false;
            for(const data of err.response.data.collection.errors[0].data) {
                if(data.name ==='login') { // traitement spécifique dans le cas de login renvoyé par l'api mais qui concerne en fait le champ email
                    this.register_data.class_API.errorsParams["email"] = new ErrorParams ('custom', this.errorReturnAPI.login);
                    specificError = true;
                    break;
                }
            }
            if (!specificError && !ErrorService.onFieldError(err, this.register_data.interface, this.register_data.class_API.errorsParams)) {
                ErrorService.onDefaultError(err);
            } else if (this.register_data.class_API instanceof APIClass) {
                this.register_data.class_API._checkErrors();
            }
        } else {
            this.errorReturnAPI.register = err.custom.messageKey;
        }
    }
    // /REGISTER

    //FREE CONSULT
    checkFreeConsultParams() {
        if(this.free_consult_data.alreadyEnter) {
            this.free_consult_data.interface.qsds_consent = !this.qsds_not_consent.free_consult; // negative question in document : true means false
            this.free_consult_data.class_API.params = this.free_consult_data.interface;
            this.free_consult_data.class_API.sendEmpty = { qsds_consent:true, weka_consent:true };
            this.free_consult_data.class_API.checkRules();
        }
    }
    freeConsult() {
        this.free_consult_data.alreadyEnter = true;
        this.checkFreeConsultParams();
        if (!this.free_consult_data.class_API.hasError) {
            this.wait_for_api = true;
            API.session.changeGuestData(
                this.free_consult_data.class_API,
                this.freeConsultSuccess,
                this.freeConsultFail
            );
        }
    }
    freeConsultSuccess(response:any) {
        this.wait_for_api = false;
        this.$store.commit('account/gotFreeConsultPass');
        this.$emit('freeConsult');
        this.$bvModal.hide('popin-login');
    }
    freeConsultFail(err:any) {
        this.wait_for_api = false;
        if (!ErrorService.onFieldError(err, this.free_consult_data.interface, this.free_consult_data.class_API.errorsParams)) {
            ErrorService.onDefaultError(err);
        }
    }
    // /FREE CONSULT


    //Choose wich popup to display
    openRegister() {
        this.login_data.show = false;
        this.register_data.show = true;
        this.free_consult_data.show = false;
    }
    openLogin() {
        this.login_data.show = true;
        this.register_data.show = false;
        this.free_consult_data.show = false;
    }
    openFreeConsult() {
        this.login_data.show = false;
        this.register_data.show = false;
        this.free_consult_data.show = true;

    }

    // Formatter
    toLowerCaseFormatter (value:string):string {
        return value.toLowerCase();
    }
    withoutControlCharacter (value:string):string {
        return value.replace(/[\u0000-\u001F\u007F-\u009F]/g, "")
    }

    // GETTERS
    get popupData() : LoginData | RegisterData | FreeConsultData {
        if (this.login_data && this.login_data.show)
            return this.login_data
        if (this.register_data && this.register_data.show)
            return this.register_data

        return this.free_consult_data
    }

    get canFreeConsult():boolean { // A supprimer ?
        return this.$store.getters['account/canFreeConsult'];
    }

    get adminURL() {
        return this.$store.getters['account/adminURL']
    }

    get isAdmin() {
        return this.$store.getters['account/isAdmin']
    }
}
