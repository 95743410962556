
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'


@Component({
    name: 'w-select'
})
export default class Select extends Vue {

    opened = false
    empty = false
    observer:MutationObserver ;

    @Prop({default: ''}) value : string
    @Prop({default: ''}) placeholder : string

    @Watch('beforeDestroy')
    beforeDestroy() {
        document.removeEventListener('click',  this.closeBlur);
    }

    @Watch('value')
    selectedValueChanged(newValue:string, oldvalue:string) {
        this.redrawMenu() ;
    }

    get selectedValue() {
        return this.value ;
    }

    set selectedValue(val:string) {
        this.value = val;
    }

    redrawMenu():void {
        const menu:any = this.$el.lastElementChild
        let item:any = this.$el.firstElementChild
        let menuItem = menu.firstElementChild
        this.empty = true
        /*
                this.observer = new MutationObserver(mutations => {
                    for (const m of mutations) {
                        if (m.type === "childList") {
                            this.$nextTick(() => {


                                const test = m.target.childNodes as NodeListOf<HTMLElement> ;
                                for (let i=0 ; i<m.target.childNodes.length ; i++) {
                                    test.item(0).click() ;
                                }

                            });
                        }
                    }
                });

                this.observer.observe(this.$el, {
                    childList: true
                });
        */
        // we find the selected input and add the "selected" attribute
        while (menuItem && item) {
            if (item.getAttribute('value') === this.value) {
                item.setAttribute('selected', '')
                menuItem.setAttribute('selected', '')
                this.empty = false
            }
            else {
                item.removeAttribute('selected')
                menuItem.removeAttribute('selected')
            }
            item = item.nextElementSibling
            menuItem = menuItem.nextElementSibling
        }
    }

    @Watch('mounted')
    mounted() {
        this.redrawMenu() ;
    }

    toggle(event?:any) {
        if(!this.opened) {
            if (event && ( event.target.classList.contains('w-select') || event.target.parentElement.classList.contains('w-select')) ) {
                this.open()
                event.stopPropagation()
            }
        } else {
            if(event) {
                if(event.target.classList.contains('w-select') || event.target.parentElement.classList.contains('w-select')) {
                   this.close();
                   event.stopPropagation()
                }
            } else {
                this.close();
            }
        }
    }

    closeBlur(event:any) {
        if(!event.target.classList.contains('w-select') && !event.target.parentElement.classList.contains('w-select')) {
            this.close();
        }
    }

    close() {
        this.opened = false;
        document.removeEventListener('click',  this.closeBlur);
        document.removeEventListener('keyup',  this.suggestItem);

    }
    open() {
        document.body.click()
        this.opened = true;
        document.addEventListener('click',  this.closeBlur);
        document.addEventListener('keyup',  this.suggestItem);
    }

    // blur(event:Event) {
    //     const node:any = this.$el
    //     if (node === document.activeElement) {
    //         event && event.preventDefault()
    //         node.blur()
    //     }
    //
    // }

    suggestItem(event:KeyboardEvent) {
        const key = event.key.toUpperCase();

        const container:Element = this.$refs['selectContainer'] as Element;
        let lastSelected:any;
        let waitingForLastSelected:boolean = false;
        if(container) {
            // @ts-ignore
            for(const child of container.childNodes) {
                if(child.classList && child.classList.contains('suggested-fading')){
                    lastSelected = child;
                }
            }

            waitingForLastSelected = !!lastSelected;
            // @ts-ignore
            for(const child of container.childNodes) {
                if(child.innerHTML && child.innerHTML.trim()[0] === key) {
                    if(lastSelected) {
                        if(waitingForLastSelected === true) {
                            if (child === lastSelected) {
                                waitingForLastSelected = false;
                            }
                        } else {
                            child.classList.add('suggested');
                            setTimeout(() => {
                                child.classList.add('suggested-fading');
                            })
                            setTimeout(() => {
                                child.classList.remove('suggested');
                                child.classList.remove('suggested-fading');
                            },1000)
                            // @ts-ignore
                            container.scrollTo(0,container.getElementsByClassName('suggested')[0].offsetTop);
                            break;
                        }
                    } else {
                        child.classList.add('suggested');
                        setTimeout(() => {
                            child.classList.add('suggested-fading');
                        })
                        setTimeout(() => {
                            child.classList.remove('suggested');
                            child.classList.remove('suggested-fading');
                        },1000)
                        // @ts-ignore
                        container.scrollTo(0,container.getElementsByClassName('suggested')[0].offsetTop);
                        break;
                    }
                }
            }
        }


    }

    //TODO: This is probably useless. Should just call redrawMenu.
    // FO: Except I don't understand where the $emit('input') goes. But it's fishy to have to call it with some innerHTML.
    onItemClick(event:any) {
        if(event.target.className === 'w-select-menu'){
            event.stopPropagation();
            return;
        }
        const item = this.getClickedItem(event.target)
        //this.selectedValue = item.getAttribute('value') ;

        this.$emit('input', item.getAttribute('value'), item.innerHTML)
        this.empty = false

        // we add the "selected" class to the selected menu item
        let child : Element|null = item.parentNode.firstElementChild
        let mainChild : any = this.$el.firstElementChild
        while (child) {
            if (child === item) {
                child.setAttribute('selected', 'selected')
                if(mainChild) mainChild.setAttribute('selected', 'selected')
            }
            else {
                child.removeAttribute('selected')
                if(mainChild) mainChild.removeAttribute('selected')
            }
            child = child.nextElementSibling
            if(mainChild) mainChild = mainChild.nextElementSibling
        }

    }

    getClickedItem(target:any) {
        const node = this.$el
        while (target.parentNode && target.parentNode.parentNode && target.parentNode.parentNode !== node)
            target = target.parentNode
        return target
    }

}
