
//STAT FORMATION
export interface Formation {
    title:string,
    source:string,
    url : string,
}

export const FormationDatas = [
    {
        title : 'Réglementation risque chimique',
        url : 'http://www.google.com',
        source : 'COMUNDI'
    },{
        title : 'gestion de la section 14',
        url : 'http://www.bing.com',
        source : 'QUICK-FDS'
    }
]
//END FORMATION