import axios from "axios";
import {ErrorDatas} from "@/components/popins/types";

class ErrorParams {

    name?:string = '';
    value?:any = '';
    customMessage?:string = '';

    constructor(name?:string, value?:any, customMessage?:string) {
        this.name = name;
        this.value = value;
        this.customMessage = customMessage;
    }

    get hasError() {
        return !!this.name || !!this.customMessage;
    }

    get message() {
        if(this.customMessage) return this.customMessage;
        if(this.name === '') return '';

        let ret = 'Error :';
        switch(this.name) {
            case 'pattern' :
                ret += ' wrong pattern ';
                break;

            case 'minLength' :
                ret += ' too short ';
                break;

            case 'maxLength' :
                ret += ' too long ';
                break;

            case 'enum' :
                ret += ' not in enum ';
                break;

            case 'required' :
                ret += ' Params required ';
                break;

            default :
                ret += ' Custom Error '
                break;
        }

        return ret + '( ' + this.name + ' : ' + this.value + ')';
    }
}

let paramsRules: any = {};
function fetch(){
    axios.get((process.env.VUE_APP_API_URL || window.location.origin + "/api/v1/") + "v3/api-docs")
        .then(function(response){
            paramsRules = response.data.components.schemas;
        })
        .catch(function(error){
        })
}

export function init() {
    fetch();
}


function getErrorForParamByType(param: any, type:string, required?:boolean):ErrorParams {
    if (required) {
        if (!param || param.length <= 0){
            return new ErrorParams('required', true);
        }

        // sepcial check for date types
        if (type === 'date') {
            if (!(param instanceof Date))
                param = new Date(param)
            if (isNaN(param))  // invalid date
                return new ErrorParams('Invalid', 'date')
        }
    }

    let rules = null;
    for(const key in paramsRules) {
        if(type === key) {
            rules = paramsRules[key];
        }
    }

    if(!rules) {
        if (paramsRules['default_string']) {
            rules = paramsRules['default_string'];
        } else {
            rules = {maxLength : 100};
        }
    }
    return getErrorForParamByRule(param, rules);
}


function getErrorForParamByRule(param: any, rules:any): ErrorParams {
    let ret:ErrorParams = new ErrorParams('','');

    Object.keys(rules).forEach(function(method){
        const rule = rules[method];
        if(method === 'maxLength') {
            if(param && param.length > rule) {
                ret = new ErrorParams(method, rule);
            }
        }

        if(method === 'minLength') {
            if(param && param.length < rule) {
                ret = new ErrorParams(method, rule);
            }
        }

        if(method === 'pattern') {
            if(param && !param.match(rule)) {
                ret = new ErrorParams(method, rule);
            }
        }

        if(method === 'enum') {
            if(param && !rule.includes(param)) {
                ret = new ErrorParams(method, rule);
            }
        }
    })

    return ret;
}

function paramsAreValid(list: any):boolean {
    if(!Object.keys(paramsRules).length) {
        return true; // FOR DEV ONLY. SHOULD WAIT FOR THE FETCH
    }

    let ret = true;
    list.forEach(function(elem:{name?:string, param:string, type: string}){
        let rules = {};
        for(const key in paramsRules) {
            if(elem.type === key) {
                rules = paramsRules[key];
            }
        }
        const err = getErrorForParamByRule(elem.param, rules)
        if(err.message !== '') {
            console.warn("ERR WITH PARAM ", elem.name, '( Value :', elem.param,' ) : ', err)
            ret = false;
        }
    });
    return ret;
}

function containError(obj: ErrorDatas): boolean {
    let ret = false;
    for(const o in obj) {
        if (obj[o].hasError) {
            ret = true;
        }
    }
    return ret;
}

export {
    paramsAreValid,
    getErrorForParamByType,
    containError,
    ErrorParams
};
