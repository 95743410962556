
import { Vue, Component, Watch} from 'vue-property-decorator'

// import global libraries

//import initiateurs

import {init as paramsCheckerInit} from '@/api/paramsChecker'
import API from "@/api/wrapper";
import {Session_GET_APIInterface, Session_GET_APIQueryInterface} from "@/api/generated/types";
import {Session_GET_APIClass} from "@/api/generated/classes";
import LanguageSwitcher from '@/components/LanguageSwitcher/component.vue'

@Component({
    components: {
        LanguageSwitcher
    }
})
export default class SimpleLayout extends Vue {

    @Watch('created')
    created() {
       this.init();      
    }

    init() {
        const lang = this.$lang;
        API.init(this, undefined, this.$router, lang);

        paramsCheckerInit();
        this.$store.commit('lang/init');
    }
}
