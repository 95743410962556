
import _Vue from "vue"

// here we declare the global properties, functions and accessors
declare module "vue/types/vue" {
  interface Vue {
    $scrollElement: Element
    $isLoggedIn: boolean
    $lang: string
  }
}

// here we define the global properties, functions and accessors
export default function VueGlobals(Vue: typeof _Vue): void {

    Object.defineProperties(Vue.prototype, {
        $scrollElement: {
            get() {
                return document.getElementById('app')
            }
        },
        $isLoggedIn: {
            get() {
                return this.$store.getters['account/isLoggedIn']
            }
        },
        $lang: {
            get() {
                return this.$store.getters['lang/current']
            }
        },
    })
}
