import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import API from '@/api/wrapper';
import ErrorService from '@/services/ErrorService';
import {
    Search_steps_GET_APIClass,
    Documents_GET_APIClass,
    Search_next_steps_GET_APIClass,
    Documents_Verify_customer_code_GET_APIClass,
    List_criterias_GET_APIClass
} from '@/api/generated/classes';
import {
    Documents_GET_APIInterface,
    Documents_GET_APIQueryInterface,
    Search_steps_GET_APIInterface,
    Search_steps_GET_APIQueryInterface,
    Search_next_steps_GET_APIInterface,
    Documents_Verify_customer_code_GET_APIInterface,
    List_criterias_GET_APIInterface,
    List_criterias_GET_APIQueryInterface,
} from '@/api/generated/types';
import {DocumentLanguage, FDSData, PaginationStats} from '@/types/FDS';
import {ACCESS_LEVELS} from '@/types/SearchState';
import APIClass from '@/api/APIClass';
import { config } from 'vuex-module-decorators';

config.rawError = true;


@Module({
    namespaced: true
})
export default class SearchState extends VuexModule {
    _waitForAPI: boolean = false;
    _supplierLogoURL: string|null = null;
    _accessLevel: ACCESS_LEVELS = ACCESS_LEVELS.PUBLIC;
    _supplierName: string|null = null;
    _customerCode: string|null = null;
    _lastSearch: any ;
    
    _currentStageNumber = 0;
    _requests: {}[] = [{value: ''}];
    _stages: {[key:string]: string|boolean}[] = [];
    _forms: {[key:string]: any}[][] = [];
    _lastUrl: string = '';

    _results:FDSData[] = [];
    _pagesStats:PaginationStats = {
        itemsCount : 0,
        pagesCount: 1,
        pageCurrent: 0
    };
    _documentType:string = '';
    _columns:string[] = [];
    _columnsTitles:string[] = [];
    _paginationLimit:number = 15;

    get waitForAPI(): boolean {
        return this._waitForAPI;
    }
    get currentStageNumber(): number {
        return this._currentStageNumber;
    }
    get requests(): {}[] {
        return this._requests;
    }
    get supplierName(): string|null {
        return this._supplierName;
    }
    get lastSearch(): any {
        return this._lastSearch;
    }
    get lastUrl(): string {
        return this._lastUrl;
    }
    get supplierLogoURL(): string|null {
        return this._supplierLogoURL;
    }
    get stages(): {[key:string]: string|boolean}[] {
        return this._stages;
    }
    get currentStage(): {[key:string]: string|boolean}|string {
        return this._stages[this.currentStageNumber] || 'pending';
    }
    get forms(): {[key:string]: any}[][] {
        return this._forms;
    }
    get currentForms(): {[key:string]: string}[] {
        return this._forms[this.currentStageNumber] || ['pending'];
    }
    get currentDocumentType() {
        return this._documentType;
    }
    get accessLevel():string {
        return this._accessLevel;
    }
    get customerCode(): string|null {
        return this._customerCode;
    }
    get results():FDSData[] {
        return this._results;
    }
    get pagesStats():PaginationStats {
        return this._pagesStats;
    }
    get columns():string[] {
        return this._columns;
    }
    get columnsTitles():string[] {
        return this._columnsTitles;
    }
    get paginationLimit():number {
        return this._paginationLimit;
    }

    @Mutation
    initSearch() {
        this._lastSearch = {};
        if (this._currentStageNumber === 0) {
            this._supplierName = null;
            this._stages = [{name: 'supplier_name', value: '', type: 'search_supplier', outro: ''}];
            this._forms = [[{input_type: 'input', input_text: 'consult_fds_supplier_name'}]];
            this._currentStageNumber = 0;
            this._requests = [{value:''}];
            this._pagesStats = {
                itemsCount : 0,
                pagesCount: 1,
                pageCurrent: 0
            };
        } else if (this._currentStageNumber < this._stages.length-1) {
            const limit = this._stages[this._currentStageNumber].type === 'search_customer_code' ? this._currentStageNumber + 2 : this._currentStageNumber +1;
            this._requests = this._requests.slice(0, limit);
            this._stages = this._stages.slice(0, limit);
            this._forms = this._forms.slice(0, limit);
        }
    }
    @Mutation
    setWaitForAPI(state:boolean) {
        this._waitForAPI = state;
    }
    @Mutation
    setSupplierName(newName:string) {
        this._supplierName = newName;
    }
    @Mutation
    setLastSearch(search :{[key:string]: string}) {
        this._lastSearch = search;
    }
    @Mutation
    setLastUrl(newUrl: string) {
        this._lastUrl = newUrl;
    }
    @Mutation
    setSupplierLogoURL(newLogoURL:string) {
        this._supplierLogoURL = newLogoURL;
    }
    @Mutation
    setCurrentStageNumber(stageNumber:number) {
        this._currentStageNumber = stageNumber;
    }
    @Mutation
    setAccessLevel(accessLevel:ACCESS_LEVELS) {
        this._accessLevel = accessLevel;
    }
    @Mutation
    setDocumentType(type:string) {
        this._documentType = type;
    }
    @Mutation
    setCustomerCode(customerCode:string) {
        this._customerCode = customerCode;
    }
    @Mutation
    addStage(payload = {name:'', value:'', type:'', outro:''}) {
        this._stages.push(payload)
    }
    @Mutation
    setStageValue(payload = {name:'', value:''}) {
        for (const stage of this._stages) {
            if (stage.name === payload.name) {
                stage.value = payload.value;
            }
        }
    }
    @Mutation
    addForms(payload:[]) {
        this._forms.push(payload)
    }
    @Mutation
    addRequests(length:number) {
        this._requests = [...this._requests, ...(Array(length).fill({value:''}))]
    }
    @Mutation
    setCurrentRequest(payload :{value?:string, fieldName?:string, operator?:string}) {
        const i = this._currentStageNumber;
        this._requests[i] = {
            ...this._requests[i],
            ...payload
        };
    }
    @Mutation
    setLabelsForLastForms(payload:[]) {
        this._forms[this.forms.length-1].push(payload)
    }

    @Mutation
    setResults(results:FDSData[]) {
        this._results = results;
        if (results.length === 0) return false;
    }
    @Mutation
    setPagesStats(stats:PaginationStats){
        this._pagesStats = stats;
    }
    @Mutation
    setColumns(results:any[]){
        this._columns = [];
        for (const result of results) {
            this._columns.push(result);
        }
    }
    @Mutation
    setColumnsTitles(results:any[]){
        this._columnsTitles = [];
        for (const result of results) {
            this._columnsTitles.push(result);
        }
    }
    @Mutation
    setPaginationLimit(limit:number) {
        this._paginationLimit = limit;
    }
    @Action
    goToStage(stage:number|string) {
        let newStageNumber = this.context.getters['currentStageNumber'];
        if (stage === '+') {
            newStageNumber++;
        } else if (stage === '-' && this.context.getters['currentStageNumber'] > 0) {
            newStageNumber--;
        } else if (typeof stage === 'number') {
            newStageNumber = stage;
        }
        if (newStageNumber > this.stages.length+1) {
            this.addStage();
        }
        this.context.commit('setCurrentStageNumber', newStageNumber);
    }
    @Action
    checkCustomerCode(value:string|null) {
        /* if no customer_code is sent */
        if (value === null) {
            this.context.commit('setStageValue', {name: 'customer_code', value: 'non'})
            return Promise.resolve('nocc')
        
        /* if there is a customer_code to test */
        } else if (value) {
            this.context.commit('setWaitForAPI', true);
            
            return new Promise((resolve, reject) => {
                const params:Documents_Verify_customer_code_GET_APIInterface = {
                    supplier_code : this.supplierName as string||this.forms[this.forms.length-1][0].supplier_name,
                    customer_code : value, 
                };
                const query = {};
                const APIClass = new Documents_Verify_customer_code_GET_APIClass(query, params);
            
                const success = (res:any) => {
                    this.context.commit('setCustomerCode', value)
                    this.context.commit('setStageValue', {name: 'customer_code', value: 'yes'})
                    this.context.commit('setWaitForAPI', false);
                    resolve(res);
                }
                const failure = (err:any) => {
                    this.context.commit('setWaitForAPI', false);
                    if (ErrorService.getCodeError(err) === 567) {
                        resolve('invalid_customer_code');
                    } else {
                        ErrorService.onDefaultError(err);
                        reject;
                    }
                }
                API.consultFDS.verifyCustomerCode(APIClass, success, failure);
            });
        } else {
            return Promise.reject('nocc')
        }
    }

    @Action
    searchBySupplierName (value:string) {
        const params: Search_steps_GET_APIInterface = {supplier_name : value};
        const query: Search_steps_GET_APIQueryInterface = {};
        const APIClass = new Search_steps_GET_APIClass(query, params);
        const request = API.consultFDS.searchBySupplierName;
        this.context.commit('setSupplierLogoURL', '');

        return new Promise((resolve, reject) => {
            this.context.dispatch('search', {request, APIClass})
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }

    @Action
    searchNextStep(value:string) {
        const query: Search_steps_GET_APIQueryInterface = {step_code: value};
        const APIClass = new Search_next_steps_GET_APIClass(query);
        const request = API.consultFDS.searchStep;
        
        return new Promise((resolve, reject) => {
            this.context.dispatch('search', {request, APIClass})
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            })
        });
    }

    @Action
    searchDocuments (payload: {[key:string]: string}) {
        if (payload.supplier_name) {
            this.context.commit('setSupplierName', payload.supplier_name);
        }

        let newParams: {[key:string]: string};
        /* if payload only contains offset, we have to take from previous search */
        if (Object.keys(payload).length === 1 && Object.keys(payload)[0] === 'offset') {
            newParams = {...this._lastSearch, ...payload}
        }
        /* if not, only take payload into account */
        else  {
            newParams = {...payload};
        }
        /* parameters are prepared for the request */
        const params:Documents_GET_APIInterface = {
            supplier_name : this.supplierName as string,
            customer_code : this.customerCode || undefined,
            limit : this.paginationLimit,
            offset : 0,
            ...newParams
        };
        /* reset results */
        this.context.commit('setResults', []);

        const query: Search_steps_GET_APIQueryInterface = {};
        const APIClass = new Documents_GET_APIClass(query, params);
        const request = API.consultFDS.searchDocuments;

        return new Promise((resolve, reject) => {
            this.context.dispatch('search', {request, APIClass})
            .then((res) => {
                /* save params for next search */
                this.context.commit('setLastSearch', newParams);
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            })
        });
    }

    @Action
    search(payload: {request:Function, APIClass:APIClass}){
        this.context.commit('setWaitForAPI', true);

        return new Promise((resolve, reject) => {
            const searchSuccess = (res:any) => {
                this.context.commit('initSearch');
                /* if items is empty, the search didn't find anything */
                if (!('items' in res.data.collection) || res.data.collection.items.length === 0) {
                    reject('not_found');
                } else {
                    const itemsType = res.data.collection.data.find((l:any) => l.name === "items_type");
                    /* The function that will be used on received data */
                    let fetchToUse:Function;
                    /* We choose the function depending on the items_type */
                    if (itemsType.value === 'quick-fds_product') {
                        fetchToUse = fetchSearchDocumentResult;
                    } else if (itemsType.value === 'search_product_form' || itemsType.value === 'select_supplier_list'){
                        fetchToUse = fetchSearchStepsResult;
                    } else {
                        fetchToUse = () => {
                            console.log("je le connais pas celui la : ", itemsType);
                        };
                    }

                    /* get the fetch result */
                    const result: {[key:string]: any}|void  = fetchToUse(res);

                    /* send the data through the commits */
                    if (result) {
                        if (result.supplierDisplayName && this.currentStageNumber === 0) {
                            this.context.commit('setStageValue', {name: 'supplier_name', value: result.supplierDisplayName});
                        }
                        if (result.accessLevel) {
                            this.context.commit('setAccessLevel', result.accessLevel);
                            if (result.accessLevel !== 'public') {
                                const customerCodeStage = {
                                    name: 'customer_code',
                                    value: '',
                                    type: 'search_customer_code',
                                    customerCodeDescription: result.customerCodeDescription || '',
                                    messageHelptext: result.messageHelptext || '',
                                    messageMandatory: result.messageMandatory === 'true'
                                }
                                this.context.commit('addStage', customerCodeStage);
                                this.context.commit('addForms', [{input_text: 'consult_fds_customer_code', input_type: 'input'}]);
                                this.context.commit('addRequests', 1);
                            }
                        }
                        if (result.supplierLogoURL) {
                            this.context.commit('setSupplierLogoURL', result.supplierLogoURL);
                        }
                        if (result.stage) {
                            this.context.commit('addStage', result.stage);
                        }
                        if (result.forms) {
                            /* set Customer code if API sent us one */
                            if (result.forms[0].customer_code) {
                                this.context.commit('setCustomerCode', result.forms[0].customer_code);
                            }
                            /* set supplier name code if API sent us one */
                            if (result.forms[0].supplier_name) {
                                this.context.commit('setSupplierName', result.forms[0].supplier_name);
                            }
                            this.context.commit('addForms', result.forms);
                        }
                        if (result.fds) {
                            this.context.commit('setResults', result.fds);
                        }
                        if (result.columns) {
                            this.context.commit('setColumns', result.columns);
                        }
                        if (result.columnsTitles) {
                            this.context.commit('setColumnsTitles', result.columnsTitles);
                        }
                        if (result.pagesStats) {
                            this.context.commit('setPagesStats', result.pagesStats);
                        }
                        if (result.documentType) {
                            this.context.commit('setDocumentType', result.documentType);
                        }
                    }
                    resolve(res);
                }
            }
            const searchFailure = (err:any) => {
                if (ErrorService.getCodeError(err) === 587) {
                    reject('missing_record');
                } else {
                    ErrorService.onDefaultError(err);
                    reject(err)
                }
            }

            //API.consultFDS.searchStep
            payload.request(payload.APIClass, searchSuccess, searchFailure)
            .finally(() => {
                this.context.commit('setWaitForAPI', false)
            })
        })
    }
}

function fetchSearchStepsResult (res: any) {
    const result: {[key:string]: any} = {};
    const nextFormsToAdd: {[key:string]: any}[] = [{labels: [], input_type: ''}];
    const stageToAdd: {[key:string]: any} = {};
    /* loop in data */
    for (const data of res.data.collection.data) {
        if (data.name === 'supplier_display_name') {
            result.supplierDisplayName = data.value;
        } else if (data.name === 'base_access_level') {
            result.accessLevel = data.value;
        } else if (data.name === 'next_property_name' && data.value !== '') {
            stageToAdd.name = data.value;
        } else if (data.name === 'intro') {
            stageToAdd.intro = data.value;
        } else if (data.name === 'outro') {
            stageToAdd.outro = data.value;
        } else if (data.name === 'customer_code_description') {
            result.customerCodeDescription = data.value;
        } else if (data.name === 'message_helptext') {
            result.messageHelptext = data.value;
        } else if (data.name === 'message_mandatory') {
            result.messageMandatory = data.value;
        } else if (data.name === 'items_type') {
            stageToAdd.type = data.value;
            if (data.value === 'search_product_form') {
                stageToAdd.name = data.value;
            }
        } else if ('name' in data && 'value' in data){
            console.log('N\'a pas été pris en compte : ', data)
        }
    }
    /* add the next stage */
    result.stage = stageToAdd;

    /* if not multi form, we can guess the input_type from stage.type */
    if (stageToAdd.type.substr(0,6) === 'select') {
        nextFormsToAdd[0].input_type = 'select';
    } else if (stageToAdd.type.substr(0,6) === 'search') {
        nextFormsToAdd[0].input_type = 'input';
    }

    /* loop in links */
    if ('links' in res.data.collection) {
        for (const link of res.data.collection.links) {
            if (link.rel === 'logo') {
                result.supplierLogoURL = link.href;
            }
        }
    }
    /* loop in items */
    if ('items' in res.data.collection) {
        if (stageToAdd.type === 'select_supplier_list') {
            for (const item of res.data.collection.items) {
                const code = item.href.split('/').pop();
                nextFormsToAdd[0].labels.push({value:code, name: item.data[0].value});
            }
        } else {
            for (const item of res.data.collection.items) {
                /* we need to add the form in an order wich will be defined in item */
                const formToAdd:{[key:string]: any} = {labels: []};
                let i;
                /* retrieve informations in the formToAdd */
                for (const data of item.data) {
                    formToAdd[data.name] = data.value;
                    /* Take the field order to determine the index of this formToAdd */
                    if (data.name === 'field_order') {
                        i = data.value
                    /* Loop throught values_list to generate les labels */
                    } else if (data.name === 'values_list') {
                        for (const key in data.value) {
                            formToAdd.labels.push({value: key, name: data.value[key]});
                        }
                    }
                }
                nextFormsToAdd[i] = {...nextFormsToAdd[i], ...formToAdd};
            }
        }
    }
    /* add the forms */
    result.forms = nextFormsToAdd;
    return result;
}


/* ancien code à vérifier */
function fetchSearchDocumentResult (response:any) {
    const result: {[key:string]: any} = {fds: []}; 

    let ufi_consult = false;
    for (let i = 0; i < response.data.collection.data.length; i++ ) {
        if (response.data.collection.data[i].name === 'ufi_consult') {
            ufi_consult = true;
            break;
        }
    }
    if ('items' in response.data.collection) {
        for (const item of response.data.collection.items) {
            const tempFDSData: FDSData = {
                language: '',
                iddc: '',
                product_name: '',
                product_code: '',
                revision_date: '',
                supplier_name: '',
                revision_version: '',
                myFDS: false,
                ufi_consult: ufi_consult,
                documents_languages: [],
                rel_ufi: 'null',
            };
            for (const data of item.items[0].data) {
                tempFDSData[data.name] = data.value;
                if (data.name === 'document_code') {
                    tempFDSData['iddc'] = data.value;
                }
            }
            if (!tempFDSData['code'] || tempFDSData['code'] === '') {
                // Si pas de code dans le document alors on prend celui du produit
                for (const data of item.data) {
                    if (data.name === 'code') {
                        tempFDSData['code'] = data.value;
                        break;
                    }
                }
            }
            if (item.items[0].links) {
                for (const link of item.items[0].links) {
                    switch (link.rel) {
                        case 'pdf':
                            const tempDocumentLanguage: DocumentLanguage = {lang: tempFDSData.language, iddc: tempFDSData.iddc};
                            // @ts-ignore
                            tempFDSData.documents_languages.push(tempDocumentLanguage);
                            break;
                        case 'pdf_ufi':
                            tempFDSData['rel_ufi'] = link.rel;
                            break;
                        default:
                            break;
                    }
                }
            }
            for (const item2 of item.items) {
                const tempFDSDataOther: FDSData = {
                    language: '',
                    iddc: '',
                    product_name: '',
                    product_code: '',
                    revision_date: '',
                    supplier_name: '',
                    revision_version: '',
                    myFDS: false,
                }
                for (const data2 of item2.data) {
                    tempFDSDataOther[data2.name] = data2.value;
                    if (data2.name === 'document_code') {
                        tempFDSDataOther['iddc'] = data2.value;
                    }
                }
                if (item2.links && tempFDSData.language !== tempFDSDataOther.language) {
                    for (const link of item2.links) {
                        switch (link.rel) {
                            case 'pdf':
                                const tempDocumentLanguage: DocumentLanguage = {
                                    lang: tempFDSDataOther.language,
                                    iddc: tempFDSDataOther.iddc
                                };
                                // @ts-ignore
                                tempFDSData.documents_languages.push(tempDocumentLanguage);
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            result.fds.push(tempFDSData)
        }
    }

    /* loop for pagination */
    const tempPagination:PaginationStats = {
        itemsCount : 0,
        pagesCount: 1,
        pageCurrent: 0
    }
    if ('data' in response.data.collection) {
        for (const data of response.data.collection.data) {
            switch(data.name){
                case 'total_nb_pages' :
                    tempPagination.pagesCount =  parseInt(data.value);
                    break;

                case 'current_page' :
                    tempPagination.pageCurrent = parseInt(data.value);
                    break;

                case 'total_nb_items' :
                    tempPagination.itemsCount =  parseInt(data.value);
                    break;

                case 'columns_codes' :
                    result.columns = data.value;
                    break;
                    
                case 'columns_titles' :
                    result.columnsTitles = data.value;
                    break;

                case 'document_type' :
                    result.documentType = data.value;
                    break;

                default :
                    break;
            }
        }
    }
    /* add pagination */ 
    result.pagesStats = tempPagination;
    return result;
}