import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import {BlogArticle} from "@/widgets/BlogVignette/types";
import API from "@/api/wrapper";

@Module({
  namespaced: true
})
export default class BlogState extends VuexModule {

    _articles :BlogArticle[] = [];

    //Mutations
    @Mutation
    setArticles(newArticles:BlogArticle[]) {
        this._articles = newArticles;
    }

    //Getters
    get articles():BlogArticle[] {
        return this._articles;
    }

    //Actions
    @Action
    fetchArticles() {
        return new Promise((resolve, reject) => {
            API.blog.getArticles(this.context.rootGetters['lang/current'])
                .then((response:any) => {
                    this.context.commit('setArticles', response)
                    resolve;
                })
                .catch(reject)
        });
    }

}
