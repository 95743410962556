import Vuex from "vuex";
import Vue from "vue";

import AccountState from "./modules/AccountState";
import Languages from "./modules/Languages";
import ShoppingState from './modules/ShoppingState';
import SearchState from './modules/SearchState';
import MyFDSState from './modules/MyFDSState';
import BlogState from "@/store/modules/BlogState";
import MenusStore from './modules/MenusStore' ;
import NavigationState from './modules/NavigationState' ;
import ToolsStore from './modules/ToolsStore' ;

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        account : AccountState,
        lang: Languages,
        blog: BlogState,
        myFDS: MyFDSState,
        shopping: ShoppingState,
        search : SearchState,
        menus: MenusStore,
        navigation: NavigationState,
        tools: ToolsStore
    }
});

// Subscribe to store updates
/*
store.subscribe((mutation, state) => {
    // If the module is shopping
    if (mutation.type.startsWith("shopping")){
        // we need to filter the cart to only offersId and quantity
        const cartFiltered = Object.fromEntries(
            Object.entries(store.getters['shopping/cart'])
            .filter(a => ['offers','quantity'].indexOf(a[0]) > -1)
        );  
        // Store the state object as a JSON string
        localStorage.setItem('cart', JSON.stringify(cartFiltered));
    }
});
*/


// //Exemple of lazy loading
// import("./modules/MyFDSState").then(MyFDSState => {
//   //@ts-ignore
//   store.registerModule('fds', MyFDSState);
// });



export default store;
