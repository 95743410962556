import {METHOD} from "./enums";
import {ErrorParams} from '@/api/paramsChecker'


function packageData(data:any, sendEmpty:any):FormData {
    const form = new FormData()
    for ( const key in data ) {
        let dataKey = data[key];
        if(dataKey || (sendEmpty && sendEmpty[key])) {
            if(Array.isArray(dataKey)){
                for ( const value of dataKey ) {
                    form.append(key, value);
                }
            } else {
                if (dataKey === null) {
                    dataKey = '';
                }
                form.append(key, dataKey);
            }
        }
    }
    return form;
}

export default class APIClass {

    errorsParams:{[key:string]:ErrorParams} = {};
    params?:any;
    sendEmpty?:any;
    responseType:string = 'json';
    requestType:string = '';
    query?:any;
    url:string = "/";
    method:METHOD = METHOD.GET;
    _hasErrors:boolean = false;

    constructor(query?:any, params?:any, sendEmpty?:any) {
        if(params) {
            this.params = params;
        }
        if(query) {
            this.query = query;
        }
        if(sendEmpty) {
            this.sendEmpty = sendEmpty;
        }
    }

    generateURL():string {
        let ret = "";
        let splited = [];
        splited = this.url.split('{');
        ret += splited[0];
        for(const splitIndex in splited) {
            if(parseInt(splitIndex) > 0){
                const split = splited[splitIndex].split('}')
                ret +=this.query[split[0]] + split[1];
            }
        }

        return ret;
    }

    checkRules() {
        for(const paramKey in this.params) {
            delete this.errorsParams[paramKey];

            if(this.rules[paramKey]) {
                const rule = this.rules[paramKey];
                const param = this.params[paramKey];

                // If type 'string'
                if(rule['type'] === 'string') {
                    for(const ruleKey in rule){
                        switch (ruleKey) {
                            case 'maxLength' : // Size max
                                if(param && param.length > parseInt(rule[ruleKey])) {
                                    this._addError(paramKey,ruleKey, rule[ruleKey]);
                                }
                                break;

                            case 'minLength' : // Size min
                                if(param && param.length < parseInt(rule[ruleKey]) - 1 ) {
                                    this._addError(paramKey, ruleKey, rule[ruleKey]);
                                }
                                break;

                            case 'format' : // Special format ( password, email ... )
                                if (param && param.length) {
                                    const strRegEmail = "[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z0-9]+"
                                    switch(rule[ruleKey]) {
                                        case 'date' :
                                            break;  //TODO format check date

                                        case 'binary' :
                                            break;  //TODO format check binary

                                        case 'email' :
                                            const regEmail = new RegExp("^" + strRegEmail + "$");
                                            if(!regEmail.test(this.params[paramKey])) {
                                                this._addError(paramKey, ruleKey, rule[ruleKey]);
                                            }
                                            break;  //TODO format check email

                                        case 'login' :
                                            const regLogin = new RegExp("^" + strRegEmail + "(\\/" + strRegEmail + ")?$");
                                            if(!regLogin.test(this.params[paramKey])) {
                                                this._addError(paramKey, ruleKey, rule[ruleKey]);
                                            }
                                            break;  //TODO format check password

                                        case 'password' :
                                            break;  //TODO format check password

                                        default:
                                            break;
                                    }
                                }
                                break;

                            case 'pattern' : // RegEx
                                if(param && param.length && !param.match(rule[ruleKey])) {
                                    this._addError(paramKey,ruleKey, rule[ruleKey]);
                                }
                                break;

                            case 'enum' : // RegEx
                                if(param && !rule.enum.includes(param)) {
                                    this._addError(paramKey, ruleKey, rule[ruleKey]);
                                }
                                break;

                            case 'required' : // RegEx
                                if(rule[ruleKey] && !param) {
                                    this._addError(paramKey, ruleKey, rule[ruleKey]);
                                }
                                break;

                            default :
                                break;
                        }
                    }
                }
            }
        }
        this._checkErrors();
    }

    _addError(paramKey:string, name:string, value:any) {
        this.errorsParams[paramKey] = new ErrorParams(name, value);
    }

    _checkErrors() {
        this._hasErrors = (Object.entries(this.errorsParams).length > 0);
    }


    get rules():{[key:string]:any} {
        return {};
    }

    get hasError() {
      return this._hasErrors;
    }

    get datas() {
        if (this.method === METHOD.DELETE || this.method === METHOD.GET) {
            return {params : this.params, responseType : this.responseType};
        } else if (this.requestType === 'json') {
            this.params['content-type'] = 'application/json';
            return this.params;
        } else {
            return packageData(this.params, this.sendEmpty);
        }
    }

}
