
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'


const SIZES : { [key:string]:string; }= {
    S : 'small',
    sm : 'small',
    small : 'small',
    M : 'medium',
    md : 'medium',
    medium : 'medium',
    L : 'large',
    lg : 'large',
    large : 'large',
    XL : 'xlarge',
    xlg : 'xlarge',
    xlarge : 'xlarge',
    full : 'fullscreen',
    fullscreen :'fullscreen',
    fs : 'fullscreen',
    FS : 'fullscreen'
}

@Component({
    name: 'w-loader'
})
export default class Loader extends Vue {
    @Prop() size?:string;
    @Prop() show?:boolean

    get shouldShow():boolean {
        return this.show !== false;
    }

    get computedClass():string {
        let ret:string = '';
        ret += this.size && (this.size in SIZES) ? SIZES[this.size] : '';
        return ret;
    }
}
