
import { Vue, Component, Watch } from 'vue-property-decorator';
import API from "@/api/wrapper";

@Component({
    name: 'language-switcher'
})
export default class LanguageSwitcher extends Vue {
    selectedLanguage: string;

    @Watch('created')
    created() {
        this.selectedLanguage = this.$lang;
        this.setLanguageInDocument();
    }

    setLanguageInDocument(lang?: string) {
        const LANG = lang || this.$lang;
        this.$i18n.locale = LANG;
        document.title = this.$i18n.t('title_site').toString();
        // @ts-ignore
        document.querySelector('html').setAttribute('lang', LANG);
    }

    generateUrlWithLang(lang?: string) {
        const LANG = lang || this.$lang;
        return "/" + LANG + this.$route.path.slice(3);
    }

    changeLanguage() {
        this.$store.commit('lang/set', this.selectedLanguage);
        API.init(this.$root, undefined, this.$router, this.selectedLanguage);

        this.setLanguageInDocument(this.selectedLanguage);
        this.$router.push(this.generateUrlWithLang());
    }
}
