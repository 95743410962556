import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Menu } from "@/components/navigation/SiteNavigation/types";
import store from '@/store';

@Module({
    namespaced: true
})
export default class MenusStore extends VuexModule {

    get lang(): string {
        return this.context.rootGetters['lang/current']
    }

    get currentFullPath() {
        return store.state.route.fullPath.slice(3); // slice lang from the path
    }

    get filteredMenus(): Menu[] {
        return getFilteredMenus(menus, this.lang);
    }
    get filteredExternalMenus(): Menu[] {
        return getFilteredMenus(externalMenus, this.lang);
    }

    get menuItemForCurrentPath(): Menu | undefined {
        return findMenuItemForPath(this.filteredMenus, this.currentFullPath);
    }

    get currentMenuTree(): Menu[] {
        const currentMenuTree: Menu[] = [];
        let currMenu: Menu | undefined = this.menuItemForCurrentPath;
        if (currMenu) {
            while (currMenu.parentMenu) {
                currentMenuTree.unshift(currMenu);
                currMenu = currMenu.parentMenu;
            }
            currentMenuTree.unshift(currMenu);
        }
        return currentMenuTree;
    }

    get fastMenuForPath(): Menu[] | undefined {

        const menuItem: Menu | undefined = this.menuItemForCurrentPath;

        if (menuItem && menuItem.subMenus) {
            // ARGL !!! Developers :-(. This "default" stuff is a bad "patch".
            if (menuItem && menuItem.subMenus && menuItem.subMenus[0].key === "default" && menuItem.subMenus[0].subMenus) {
                return menuItem.subMenus[0].subMenus;
            }
            return menuItem.subMenus;
        }

        if (menuItem && menuItem.parentMenu && menuItem.parentMenu.subMenus) {
            return menuItem.parentMenu.subMenus;
        }
    }
}

// Simple shallow object copy.
function shallowCopy(o: any): any {
    let output: any, key: any;
    output = Array.isArray(o) ? [] : {};
    for (key in o) {
        output[key] = o[key];
    }
    return output;
}
// Filter menus. For now filter is static and compares the authorized languages with the given one.
// It also adds a link to the parent menu.
function getFilteredMenus(_menus: Menu[], _lang: string, parentMenu?: Menu): Menu[] {
    const filteredMenus: Menu[] = [];
    for (const menu of _menus) {
        if (!menu.langs || menu.langs.includes(_lang)) {
            const newMenu: Menu = shallowCopy(menu);
            if (parentMenu) {
                newMenu.parentMenu = parentMenu;
            }
            filteredMenus.push(newMenu);
            if (newMenu.subMenus && newMenu.subMenus.length) {
                newMenu.subMenus = getFilteredMenus(newMenu.subMenus, _lang, newMenu);
            }
        }
    }
    return filteredMenus;
}

function findMenuItemForPath(_menus: Menu[], _path: string): Menu | undefined {
    for (const menu of _menus) {
        if (_path === menu.path || _path === menu.path + "#" || (menu.pattern && menu.pattern.test(_path))) {
            return menu;
        }
        else if (menu.subMenus) {
            const returnedMenu = findMenuItemForPath(menu.subMenus, _path);
            if (returnedMenu) return returnedMenu;
        }
    }
}

const externalMenus: Menu[] = [
    {
        key: 'fds', // used for translation
        path: '',
        text: 'FDS & Risques chimiques',
        langs: ['fr'],
        subMenus: [
            {
                key: 'risk',
                path: 'https://www.quickfds.com/solutions/le-risque-chimique-la-sante-et-la-securite/',
                text: 'Le risque chimique, la santé et la sécurité'
            },
            {
                key: 'file',
                path: 'https://www.quickfds.com/solutions/la-fiche-de-donnees-de-securite/',
                text: 'La fiche de données de sécurité'
            },
            {
                key: 'faq',
                path: 'https://www.quickfds.com/solutions/fds-sst-reach-la-faq-quick-fds/',
                text: 'Foire aux questions'
            }
        ]
    },
    {
        key: 'solutions',
        path: 'https://www.quickfds.com/solutions/',
        text: 'Nos solutions',
        langs: ['fr'],
        subMenus: [
            {
                key: 'fds-use',
                path: '',
                text: 'Utiliser les FDS',
                subMenus: [
                    {
                        key: 'collect',
                        path: 'https://www.quickfds.com/solutions/fds-retriever/',
                        text: 'Collecte des FDS'
                    },
                    {
                        key: 'exploit',
                        path: 'https://www.quickfds.com/solutions/fds-extract/',
                        text: 'Exploiter les FDS'
                    },
                    {
                        key: 'ficher-hse',
                        path: 'https://www.quickfds.com/solutions/creer-des-fiches-hse/',
                        text: ' Créer des fiches HSE'
                    },
                    {
                        key: 'vigi-susbtances',
                        path: 'https://www.quickfds.com/solutions/vigi-substances/',
                        text: 'Vigi-Substances'
                    },
                    {
                        key: 'quick-icpe',
                        path: 'https://www.quickfds.com/solutions/quick-icpe/',
                        text: 'Quick-ICPE'
                    },
                    {
                        key: 'quick-adr',
                        path: 'https://www.quickfds.com/solutions/quick-adr/',
                        text: 'Quick-ADR'
                    },
                    {
                        key: 'archivage-des-fds',
                        path: 'https://www.quickfds.com/solutions/archivage-des-fds/',
                        text: 'Archivage des FDS'
                    },
                    {
                        key: 'quick-etiquettes',
                        path: 'https://www.quickfds.com/solutions/quick-etiquettes/',
                        text: 'Quick-Étiquettes'
                    }
                ]
            },
            {
                key: 'fds-provide',
                path: '',
                text: 'Fournir les FDS',
                subMenus: [
                    {
                        key: 'hosting',
                        path: 'https://www.quickfds.com/solutions/hebergement-quick-fds/',
                        text: 'Hébergement des FDS'
                    },
                    {
                        key: 'integer-hosting',
                        path: 'https://www.quickfds.com/solutions/hebergement-integre-des-fds/',
                        text: 'Hébergement intégré des FDS'
                    },
                    {
                        key: 'transmission',
                        path: 'https://www.quickfds.com/solutions/transmettre-les-fds/',
                        text: 'Transmission des FDS'
                    },
                    {
                        key: 'transmission-proof',
                        path: 'https://www.quickfds.com/solutions/preuve-de-transmission-des-fds/',
                        text: 'Preuve de transmission des FDS'
                    },
                    {
                        key: 'one-click',
                        path: 'https://www.quickfds.com/solutions/url-one-click/',
                        text: 'FDS One-Click'
                    }
                ]
            },
            {
                key: 'consulting',
                path: '',
                text: 'Consulting',
                subMenus: [
                    {
                        key: 'consulting-audit',
                        path: 'https://www.quickfds.com/solutions/consulting/',
                        text: 'Conseil et audit'
                    },
                    {
                        key: 'quickfds-compatible-solutions',
                        path: 'https://www.quickfds.com/solutions/interoperabilite-des-solutions-quick-fds/',
                        html: '<i class="fa fa-chevron-right mr-1"></i>Solutions compatibles Quick-FDS'
                    }
                ]
            },
        ]
    },
    {
        key: 'ressources',
        path: 'https://www.quickfds.com/solutions/centre-de-ressources/',
        text: 'Livres blancs et webinars',
        langs: ['fr']
    },
]

const menus: Menu[] = [
    {
        key: 'search_fds',
        text: 'search_fds',
        path: '/search/',
        pattern: new RegExp('^/search')
    },
    {
        key: 'my_fds',
        text: 'my_fds_safebox',
        path: '/my-fds/',
    },
]
