import Vue from 'vue'
import VueI18n from 'vue-i18n'

import fr from './fr.json'
import en from './en.json'
import de from './de.json'
import it from './it.json'

Vue.use(VueI18n);

const numberFormats = {
  fr: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    }
  }
}


export const i18n = new VueI18n({
  silentTranslationWarn: true,
  fallbackLocale: {
    'de' : ['en', 'fr'],
    'default' : ['fr', 'en']
  },
  messages: {
    fr: fr,
    en: en,
    de: de,
    it: it
  },
  numberFormats
});
