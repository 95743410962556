export enum ENUM_BASE_ACCESS_LEVEL {
    PUBLIC = "public",
    PRIVATE = "private",
    MIXED = "mixed"
}

export enum ENUM_PROFILE_TYPE {
    MYSDS = "mySDS",
    ADMIN = "admin",
    ASSISTANT = "assistant",
    PUBLIC = "public",
    PRIVATE = "private",
    GUEST = "guest"
}

