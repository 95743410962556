import {Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {LanguagesEnum} from "@/types/Languages";


// return the language saved in the localStorage
function storageLang():LanguagesEnum | undefined {
    return localStorage.getItem('lang') as LanguagesEnum;
}

// return the language of the Navigator UI ( 2 letters format )
function navigatorLang():LanguagesEnum {
    return (navigator.languages ? navigator.languages[0] : (navigator.language)).split('-')[0] as LanguagesEnum;
}

@Module({
    namespaced: true
})
export default class Languages extends VuexModule {
    _currentLanguage:LanguagesEnum = storageLang() || navigatorLang()


    @Mutation
    init() {
        const slang = storageLang();
        const nlang = navigatorLang();
        if(slang) {
            this._currentLanguage = slang;
        } else {
            this._currentLanguage = nlang;
        }
    }



    // return all languages
    get all():LanguagesEnum[] { return Object.values(LanguagesEnum) }

    // return the current language
    get current():LanguagesEnum { return this._currentLanguage }

    get navigatorLanguages():LanguagesEnum[] { 
        const languages:string[] = [];
        for (const item of navigator.languages) {
            const language = item.split('-')[0];
            if (languages.indexOf(language)) {
                languages.push(language)
            }
        }
        return languages as LanguagesEnum[];
    }

    // set the current language
    @Mutation set(lang:LanguagesEnum) {
        this._currentLanguage = lang
        localStorage.setItem('lang', lang);
    }

}
