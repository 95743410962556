
import {Vue, Component, Watch} from 'vue-property-decorator'
import {Show, ShowDatas} from "@/views/static/ShowsAndEvents/showDatas";
import {Conf12mnDatas} from "@/views/static/ShowsAndEvents/conf12mnDatas";
import {Formation, FormationDatas} from "@/views/static/ShowsAndEvents/formationDatas";
import {BlogArticle} from '@/widgets/BlogVignette/types';

@Component
export default class ShowsAndEvents extends Vue {

    toTimestamp(dateStart:string){
        return Date.parse(dateStart.split('/').reverse().join('-'));
    }

    @Watch("$lang")
    onChangeLang() {
    }

    openURL(url:string) {
        window.open(url, '_blank');
    }

    get shows():Show[] {
        return ShowDatas.filter(a=>this.toTimestamp(a.dateStart)>=Date.now()); // filter on Shows to make sure there not already old
    }

    get formations():Formation[] {
        return FormationDatas;
    }

    get formationsBySource():{[key:string]:Formation[]} {
        const ret:{[key:string]:Formation[]} = {};
        for(const formation of this.formations){
            if(!ret[formation.source]) {
                ret[formation.source] = [];
            }
            ret[formation.source].push(formation);
        }
        return ret;
    }

    get conf12mn():BlogArticle[] {
        return this.$store.getters['blog/articles'];
    }

}