
import {Vue, Component, Watch, Prop} from 'vue-property-decorator'


@Component({
    name: 'splash-screen',
})
export default class SplashScreen extends Vue {

    @Prop()
    secondsBeforeAutoHide:number;

    defaultSecondsBeforeAutoHide:number = 15;
    display:boolean = this._display;

    close() {
        if (!window.location.hostname.includes("beta"))
            localStorage.setItem('display_splash', 'false')
        this.display = false;
    }

    @Watch('created')
    created(){
        setTimeout(() => {
            this.close();
        }, (this.secondsBeforeAutoHide ? this.secondsBeforeAutoHide  : this.defaultSecondsBeforeAutoHide) * 1000)
    }

    get isIE() {
        //@ts-ignore
        return (get_browser().name === 'MSIE' || get_browser().name === 'IE') ;
    }

    get _display() {
//        if (!this.isIE) return false ;
        if (!(window.location.hostname.includes("beta") || window.location.hostname.includes("localhost"))) {
            if (localStorage.getItem('display_splash') === 'false') {
                return false;
            }
        }
        // if(document.cookie) {
        //     return false;
        // }

        return true;
    }

}
