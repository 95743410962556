import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import API from "@/api/wrapper";
import {DocumentLanguage, FDSData, PaginationStats} from "@/types/FDS";
import {
    Users_Login_Profile_type_Documents_Document_code_DELETE_APIInterface,
    Users_Login_Profile_type_Documents_Document_code_DELETE_APIQueryInterface,
    Users_Login_Profile_type_Documents_Document_code_SelectInfReg_PUT_APIQueryInterface,
    Users_Login_Profile_type_Documents_GET_APIInterface,
    Users_Login_Profile_type_Documents_GET_APIQueryInterface,
    Users_Login_Profile_type_Documents_POST_APIInterface,
    Users_Login_Profile_type_Documents_POST_APIQueryInterface,
    Users_Login_Profile_type_History_GET_APIInterface,
    Users_Login_Profile_type_History_GET_APIQueryInterface,
    Users_Login_MySDS_Documents_Suppliers_GET_APIInterface,
    Users_Login_MySDS_Documents_Suppliers_GET_APIQueryInterface,
    Users_Login_MySDS_Documents_Export_GET_APIInterface,
    Report_Data_GET_APIQueryInterface,
    Report_Vigi_GET_APIQueryInterface
} from "@/api/generated/types";
import {ENUM_PROFILE_TYPE} from "@/api/generated/enums";
import {
    Users_Login_Profile_type_Documents_Document_code_DELETE_APIClass,
    Users_Login_Profile_type_Documents_Document_code_SelectInfReg_PUT_APIClass,
    Users_Login_Profile_type_Documents_GET_APIClass,
    Users_Login_Profile_type_Documents_POST_APIClass,
    Users_Login_Profile_type_History_GET_APIClass,
    Users_Login_MySDS_Documents_Suppliers_GET_APIClass,
    Users_Login_MySDS_Documents_Export_GET_APIClass,
    Report_Data_GET_APIClass,
    Report_Vigi_GET_APIClass
} from "@/api/generated/classes";
import {FDSFilter} from "../../types/FDS";
import ErrorService from "@/services/ErrorService";

@Module({
    namespaced: true
})
export default class MyFDSState extends VuexModule {

    importing:boolean = false;
    fdsList:FDSData[] = [];
    fdsListCache:FDSData[] = [];
    fdsListbySupplier:FDSData[] = [];
    fetchingFds:boolean = false;

    // This is the minimal number of records that will be requested from the server for MyFDSs.
    // It allows local caching.
    static minimumLimit:number = 500 ;

    //supplierList:string[] = [];
    supplierList: { [key:string]:string; }|null = null ;
    fetchingSupplierList:boolean = false ;

    _pagesStats:PaginationStats = {
        itemsCount : 0,
        pagesCount: 0,
        pageCurrent: 0
    };

    currentFilters:FDSFilter[] = [] ;

    //Mutations
    @Mutation
    setFetching(val:boolean){
        this.fetchingFds = val;
    }
    @Mutation
    setFDSList(val:FDSData[]){
        this.fdsList = val.slice(0) ;
    }

    // Cache handling
    // Using an empty object will reset the cache
    // Args : a val object containing :
    // - A "list" key containing an array of FDSData
    // - An "index" key : Start copying the list at specific index in the cache
    @Mutation
    setFDSListCache(val:{ [key:string]:any } = {}) {
        if (Object.keys(val).length === 0) {
            this.fdsListCache.length = 0 ;
            return ;
        }

        if (!("list" in val) || !("index" in val)) return ;

        // Copy
        for (let i:number = 0 ; i<val["list"].length ; i++) {
            this.fdsListCache[val["index"]+i] = val["list"][i] ;
        }
    }

    get FdsListCache():FDSData[] {
        return this.fdsListCache ;
    }

    @Action
    clearFDSListCache():void {
        this.context.commit('setFDSListCache', {});
    }

    // Checks if all cells from the FDSArray from startIdx to startIdx+nb-1
    static isFDSArrayFilled(arr:FDSData[], startIdx:number, nb:number):boolean {
        if (arr.length < startIdx+nb-1) return false ;
        for (let i:number = startIdx ; i<startIdx+nb ; i++) {
            if (arr[i] === undefined || arr[i] === null) return false ;
        }
        return true ;
    }

    static removeFDSFromList(arr:FDSData[], documentCode: string) {
        let idx:number = -1 ;
        for (let i:number=0 ; i<arr.length ; i++) {
            if (arr[i] !== undefined && arr[i].iddc === documentCode) {
                idx = i ;
                break ;
            }
        }
        if (idx >= 0) {
            return arr.splice(idx, 1) ;
        }
        return arr ;
    }

    @Mutation
    setImport(val:boolean){
        this.importing = val;
    }
    @Mutation
    setSupplierList(val:{ [key:string]:string; } = {}) {
        this.supplierList = val;
        this.fetchingSupplierList = false ;
    }
    @Mutation
    setFetchingSuppliersList(val:boolean){
        this.fetchingSupplierList = false ;
    }
    @Mutation
    setPagesStats(stats:PaginationStats){
        this._pagesStats = stats;
    }
    @Mutation
    setFDSBySupplier(val:FDSData[]){
        this.fdsListbySupplier = val;
    }

    @Mutation
    setCurrentFilters(val:FDSFilter[]) {
        this.currentFilters = val ;
    }

    //Getters
    get pagesStats():PaginationStats {
        return this._pagesStats;
    }

    get fetching():boolean {
        return this.fetchingFds;
    }

    get fetchingSuppliersList():boolean {
        return this.fetchingSupplierList;
    }

    get list():FDSData[]{
        return this.fdsList;
    }
    get isImporting():boolean {
        return this.importing
    }
    get suppliers(): { [key:string]:string; } {
        return this.supplierList as any;
    }
    get fdsBySupplier():Object{
        return this.fdsListbySupplier;
    }
    get CurrentFilters():FDSFilter[] {
        return this.currentFilters ;
    }

    @Action
    extractSupplierList() {
        this.context.commit('setFetchingSuppliersList', true);

        // If not dynamic use this.context.commit("mutationName", payload) and this.context.getters["getterName"]
        return new Promise((resolve, reject) => {

        const supplierList: { [key:string]:string; } = {} ;

        const profil = this.context.rootGetters['account/profil'];

        const params:Users_Login_MySDS_Documents_Suppliers_GET_APIInterface = {};
        const query:Users_Login_MySDS_Documents_Suppliers_GET_APIQueryInterface = {
            login : profil.login,
        };
        API.callWithClass(
            new Users_Login_MySDS_Documents_Suppliers_GET_APIClass(query, params),
            (response: any) => {
            for (const myFDS of response.data.collection.items) {
                if (myFDS.data[0].name === 'supplier_name' && myFDS.data[1].name === 'supplier_code') {
                supplierList[myFDS.data[1].value] = myFDS.data[0].value ? myFDS.data[0].value : '' ;
                }
            }
            this.context.commit('setSupplierList', supplierList) ;
            },
            (error: any) => {
                this.context.commit('setSupplierList', null) ;
                this.context.commit('setFetching', false);
                ErrorService.onDefaultError(error);
                reject();
            }
        );
        });
    }


    @Action
    extractListFDSDataFromResponse(payload:{response:any, myFDS:boolean, getPageStats?:boolean}){
        return new Promise((resolve, reject) => {

        //Pagination
        const temp:PaginationStats = {
            itemsCount : 0,
            pagesCount: 1,
            pageCurrent: 0
        }
        if(payload.response.data.collection.data) {
            let nbItemsSeen = false ;
            for(const data of payload.response.data.collection.data) {
                switch(data.name){
                    case 'total_nb_pages' :
                    temp.pagesCount =  parseInt (data.value);
                    break;

                    case 'current_page' :
                    temp.pageCurrent =  parseInt (data.value);
                    break;

                    case 'total_nb_items' :
                    if (!nbItemsSeen) {
                        temp.itemsCount =  parseInt (data.value);
                        nbItemsSeen = true ;
                    }
                    break;

                    default :
                    break;
                }
            }
        }
        if(payload.getPageStats) {
            this.context.commit('setPagesStats', temp);
        }

        //Datas
        const arr: any[] = payload.response.data.collection.items;
        const ret: FDSData[] = [];
        for (const item of arr) {
            // Pour documents il y a deux niveaux d'items mais pour history il n'y a qu'un seul niveau
            for (const item2 of (item.items ? item.items : item)) {
                const obj: FDSData = {
                    language: '',
                    iddc: '',
                    product_name: '',
                    product_name_slug: '',
                    supplier_name: '',
                    supplier_name_slug: '',
                    myFDS: true,
                    documents_languages: []
                };

                for (const data of item.data) {
                    if (data.name === 'supplier_name') {
                        obj.supplier_name = data.value;
                    } else if (data.name === 'supplier_name_slug') {
                        obj.supplier_name_slug = data.value;
                    }
                }

                for (const data2 of item2.data) {
                    if (data2.name === 'document_code') {
                        obj.iddc = data2.value;
                        if (payload.myFDS === false) { // Add "Favorites" for History mode ( or others )
                            obj.myFDS = false;
                            const myFDS = this.context.getters['list'];
                            for (const FDS of myFDS) {
                                if (FDS.iddc === obj.iddc) {
                                    obj.myFDS = true;
                                }
                            }
                        }
                    } else {
                        obj[data2.name] = data2.value;
                    }
                }
                if (obj.iddc) {
                    const tempDocumentLanguage: DocumentLanguage = {lang: obj.language, iddc: obj.iddc};
                    // @ts-ignore
                    obj.documents_languages.push(tempDocumentLanguage);
                }
                ret.push(obj);
            }
        }

        this.context.dispatch('extractSupplierList')
        this.context.commit('setFetching', false);

        resolve(ret)
        });
    }

    @Action
    addHistoryToMySDS(payload:{response:any, myFDS:boolean, getPageStats?:boolean}){
        return new Promise((resolve, reject) => {
        //Datas
        const docsCodes: string[] = [];
        const mySDSs = (this.context.getters['list'] as FDSData[]).map(item => item.iddc);
        if (payload.response.data.collection.items) {
            for (const item of payload.response.data.collection.items) {
            for (const data of item.data) {
                if (data.name === "document_code") {
                const iddc = data.value;
                if (!mySDSs.includes(iddc)) {
                    docsCodes.push(iddc);
                }
                }
            }
            }
        } else {
            for (const item of payload.response.data.collection) {
            for (const data of item.data) {
                if (data.name === "document_code") {
                const iddc = data.value;
                if (!mySDSs.includes(iddc)) {
                    docsCodes.push(iddc);
                }
                }
            }
            }
        }
        
        if (docsCodes.length) {
            this.context.dispatch('addToMyFDS', docsCodes)
            .then(() => { resolve(docsCodes)} )
            .catch(() => { reject()} );
        } 
        });
    }

    //IMPORT
    @Action
    importHistory(ignoreProfileDocuments:boolean = false){
        return new Promise((resolve, reject) => {

        this.context.commit('setImport', true);
        this.context.commit('setFetching', true);
        const profil = this.context.rootGetters['account/profil'];

        const params:Users_Login_Profile_type_History_GET_APIInterface = {
            ignore_profile_documents : ignoreProfileDocuments
        };
        const query:Users_Login_Profile_type_History_GET_APIQueryInterface = {
            login : profil.login,
            profile_type : ENUM_PROFILE_TYPE.MYSDS
        };
        API.callWithClass(
            new Users_Login_Profile_type_History_GET_APIClass(query, params),
            (response: any) => {
            if (!response.data.collection.items) {
                reject();
            }
            this.context.dispatch('addHistoryToMySDS', {response : response, myFDS : true, getPageStats : true})
                .then((res:string[]) => {
                resolve(res);
                });
            },
            (error: any) => {
                this.context.commit('setFetching', false);
                ErrorService.onDefaultError(error);
                reject();
            }
        );
        });
    }

    static areFiltersDifferent(newFilters:FDSFilter[], oldFilters:FDSFilter[]) {
        if (oldFilters === undefined) return true ;
        if (newFilters === undefined) return true ;

        if (JSON.stringify(newFilters) !== JSON.stringify(oldFilters)) {
        return true ;
        }
        else {
        return false ;
        }
    }
    

    // EXPORT
    @Action
    exportMyFDS(zip:boolean = false) {
        const isJsonBlob = (data:any) => data instanceof Blob && data.type === "application/json";

        return new Promise((resolve, reject) => {

            const params : Users_Login_MySDS_Documents_Export_GET_APIInterface = {
                zip : zip
            };
            const query : Users_Login_Profile_type_Documents_POST_APIQueryInterface = {
                login : this.context.rootGetters['account/profil'].login,
                profile_type : ENUM_PROFILE_TYPE.MYSDS
            };
            const API_class : Users_Login_MySDS_Documents_Export_GET_APIClass = new Users_Login_MySDS_Documents_Export_GET_APIClass(query, params);

            const exportMyFDSSuccess:Function = (response:any) => {
                const dataType = response.headers['content-type'];
                
                if (!zip && dataType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || zip && response.headers['content-type'] === 'application/zip') {
                    resolve(response)
                } else {
                    reject('wrong data content')
                }
            }
            const exportMyFDSFail:Function = async (error:any) => {
                // get JSON response from blob response
                const responseData = isJsonBlob(error.response.data) ? await (error.response.data).text() : error.response.data || {};
                const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;

                ErrorService.onDefaultError(error);
                reject();
            }

            return API.myFDS.exportFds(
                API_class,
                exportMyFDSSuccess,
                exportMyFDSFail
            );
        });
    }
    // GET REPORT
    @Action
    exportDataReport(documentCode:string) {
        const isJsonBlob = (data:any) => data instanceof Blob && data.type === "application/json";

        return new Promise((resolve, reject) => {
            const query : Report_Data_GET_APIQueryInterface = {
                document_code: documentCode
            };
            const API_class : Report_Data_GET_APIClass = new Report_Data_GET_APIClass(query);

            const exportSuccess: Function = (response:any) => {
                const dataType = response.headers['content-type'];
                
                if (dataType === 'application/pdf') {
                    resolve(response)
                } else {
                    reject('wrong data content')
                }
            }
            const exportFail: Function = async (error:any) => {
                // get JSON response from blob response
                const responseData = isJsonBlob(error.response.data) ? await (error.response.data).text() : error.response.data || {};
                const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;

                ErrorService.onDefaultError(error);
                reject();
            }

            return API.report.getFdsData(
                API_class,
                exportSuccess,
                exportFail
            );
        });
    }
    // GET REPORT
    @Action
    exportVigiSubstance(documentCode:string) {
        const isJsonBlob = (data:any) => data instanceof Blob && data.type === "application/json";

        return new Promise((resolve, reject) => {
            const query : Report_Vigi_GET_APIQueryInterface = {
                document_code: documentCode
            };
            const API_class : Report_Vigi_GET_APIClass = new Report_Vigi_GET_APIClass(query);

            const exportSuccess: Function = (response:any) => {
                const dataType = response.headers['content-type'];
                
                if (dataType === 'application/pdf') {
                    resolve(response)
                } else {
                    reject('wrong data content')
                }
            }
            const exportFail: Function = async (error:any) => {
                // get JSON response from blob response
                const responseData = isJsonBlob(error.response.data) ? await (error.response.data).text() : error.response.data || {};
                const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;

                ErrorService.onDefaultError(error);
                reject();
            }

            return API.report.getVigiSubstances(
                API_class,
                exportSuccess,
                exportFail
            );
        });
    }

    // GET FAVORITES
    @Action({ rawError: true })
    getMyFDS(payload:{limit:number, offset : number, filters:FDSFilter[]}) {

        // Check for guest profile. Don't issue request if is guest.
        if (!this.context.rootGetters['account/isLoggedIn']) {
            // Empty FDS List Cache
            this.context.commit('setFDSListCache', {});
            return;
        }
        const pagesStats:PaginationStats = this.context.getters["pagesStats"];

        if (payload.filters !== undefined) {
            if (MyFDSState.areFiltersDifferent(payload.filters, this.context.getters["CurrentFilters"])) {
                // Empty FDS List Cache
                this.context.commit('setFDSListCache', {});
                // Empty Pagination stats
                pagesStats.itemsCount = 0 ;
                pagesStats.pageCurrent = 0 ;
                pagesStats.pagesCount = 0 ;
            }

            // Store the requested filters. We don't need the previous version anymore
            this.context.commit('setCurrentFilters', payload.filters);

            // Check cache. If it's filled, return the needed entries
            if (MyFDSState.minimumLimit > 0 && pagesStats.itemsCount > 0) {
                const fdsListCache = this.context.getters["FdsListCache"];
                // Check for the upper expected limit
                // (e.g: Cache contains only 95 entries. So can't check for offset : 90 and limit : 10).
                const toCheckNb: number = Math.min(payload.offset + payload.limit, pagesStats.itemsCount) - payload.offset;

                if (MyFDSState.isFDSArrayFilled(fdsListCache, payload.offset, toCheckNb)) {
                    this.context.commit('setFDSList', fdsListCache);
                    pagesStats.pageCurrent = Math.round(payload.offset / payload.limit) + 1;
                    this.context.commit('setPagesStats', pagesStats);
                    return;
                }
            }

        }
        return new Promise((resolve, reject) => {
            this.context.commit('setFetching', true);

            // Change the request parameters to fill in the cache with more information than requested
            const startIdx = payload.offset ;
            const cachedLimit = Math.max(MyFDSState.minimumLimit, payload.limit) ;

            // Create the request and add its params
            const params:Users_Login_Profile_type_Documents_GET_APIInterface = {
                limit : cachedLimit,
                offset : startIdx,
            };
            if (payload.filters !== undefined && payload.filters !== null) {
                for (const filter of payload.filters) {
                    if (filter.field === "product_name") params.product_name = filter.value ;
                    if (filter.field === "supplier_code") params.supplier_code = filter.value ;
                    if (filter.field === "sort_by") params.sort_by = filter.value ;
                    if (filter.field === "sort_direction") params.sort_direction = filter.value ;
                }
            }
            const query:Users_Login_Profile_type_Documents_GET_APIQueryInterface = {
                login : this.context.rootGetters['account/profil'].login,
                profile_type : ENUM_PROFILE_TYPE.MYSDS
            };

            API.callWithClass(
                new Users_Login_Profile_type_Documents_GET_APIClass(query, params),
                (response:any) => {
                    this.context.dispatch('extractListFDSDataFromResponse', {response : response, myFDS : true, getPageStats : true})
                    .then((res:FDSData[]) => {
                        // Check if this request correspond to the most recent filters
                        // If not, it probably came back later than a mor recent one
                        // In this case, discard the results.
                        // TODO : Should probably do the same for offset and limit.
                        //  We could also queue the requests and wait for each one to finish before launching the next one, but it would slow down everything.
                        //  For performance reasons, we should also use debounce on the product names filter :
                        //  https://fr.vuejs.org/v2/guide/computed.html and https://lodash.com/docs#debounce
                        if (payload.filters !== undefined && MyFDSState.areFiltersDifferent(payload.filters, this.context.getters["CurrentFilters"])) {
                            resolve({}) ;
                            return ;
                        }

                        // Store the new entries into the cache
                        this.context.commit('setFDSListCache', { "index" : startIdx, "list" : res }) ;
                        this.context.commit('setFDSList', this.context.getters["FdsListCache"]) ;

                        // Get the current PaginationStats and adjust them
                        // (so they reflect the requested information and not what we asked the backend)
                        const pagesStats:PaginationStats = this.context.getters["pagesStats"] ;
                        pagesStats.pagesCount = Math.ceil(pagesStats.itemsCount/payload.limit) ;
                        pagesStats.pageCurrent = Math.round(payload.offset/payload.limit) ;
                        this.context.commit('setPagesStats', pagesStats) ;
                        resolve(res);
                    });
                },
                (error:any) => {
                    this.context.commit('setFetching', false);
                    if (ErrorService.getCodeError(error) === 564) {
                        ErrorService.displayCustomError("Votre compte MES-FDS ne fonctionne pas, veuillez contacter notre support")
                    } else if (ErrorService.getCodeError(error) === 562) {
                        ErrorService.displayCustomError(ErrorService.getMessageError(error))
                    } else {
                        ErrorService.displayCustomError("The data could not be retrieved, please try again later");
                    }
                    reject(error);
                }
            );

        });
    }

    // ADD TO FAVORITES
    @Action
    addToMyFDS(documentCode: string[]) {
        this.context.commit('setFDSListCache', {}) ;
        return new Promise((resolve, reject) => {
            if (documentCode.length) {
                const profil = this.context.rootGetters['account/profil'];

                const addToMyFDSSuccess:Function = (response:any) => {
                    this.context.dispatch('extractListFDSDataFromResponse', {response : response, myFDS : false, getPageStats : false})
                    .then((res:FDSData[]) => {
                        this.context.commit('setFDSList', res);
                    })
                    .finally(() => {
                        resolve(response);
                    });
                };
                const addToMyFDSFail:Function = (error:any) => {
                    ErrorService.onDefaultError(error);
                    reject;
                };

                const params:Users_Login_Profile_type_Documents_POST_APIInterface = {
                    document_code : documentCode
                };
                const query:Users_Login_Profile_type_Documents_POST_APIQueryInterface = {
                    login : profil.login,
                    profile_type : ENUM_PROFILE_TYPE.MYSDS
                };
                const API_class:Users_Login_Profile_type_Documents_POST_APIClass = new Users_Login_Profile_type_Documents_POST_APIClass(query, params);

                API.myFDS.addDoc(
                    API_class,
                    addToMyFDSSuccess,
                    addToMyFDSFail
                );
            } else {
                reject;
            }
        });
    }
    // ADD REG/INF TO FAVORITES
    @Action
    addInfRegToFav(documentCode: string) {
        this.context.commit('setFDSListCache', {}) ;
        return new Promise((resolve, reject) => {
            if(documentCode.length > 0) {
                const profil = this.context.rootGetters['account/profil'];

                const addToMyFDSSuccess:Function = (response:any) => {
                    const stats:PaginationStats = this.context.getters["pagesStats"] ;
                    const itemsPerPage:number = Math.ceil(stats.itemsCount/stats.pagesCount) | 1 ;
                    const offset:number = (stats.pageCurrent -1 ) * itemsPerPage ;

                    this.context.dispatch('clearFDSListCache');
                    this.context.dispatch('getMyFDS', {
                        limit : 500,
                        offset : offset,
                        filters : this.context.getters["CurrentFilters"]
                    })
                    .finally(() => {
                        resolve(response);
                    });
                };
                const addToMyFDSFail:Function = (error:any) => {
                    ErrorService.onDefaultError(error);
                    reject;
                };

                const query:Users_Login_Profile_type_Documents_Document_code_SelectInfReg_PUT_APIQueryInterface = {
                    login : profil.login,
                    document_code : documentCode,
                    profile_type : ENUM_PROFILE_TYPE.MYSDS
                };
                const API_class:Users_Login_Profile_type_Documents_Document_code_SelectInfReg_PUT_APIClass = new Users_Login_Profile_type_Documents_Document_code_SelectInfReg_PUT_APIClass(query);

                API.myFDS.addInfReg(
                    API_class,
                    addToMyFDSSuccess,
                    addToMyFDSFail
                );
            } else {
                reject;
            }
        });
    }
    // REMOVE FROM FAVORITES
    @Action
    removeFromMyFDS(documentCode: string) {
        const pagesStatsBefore:PaginationStats = this.context.getters["pagesStats"] ;
        const itemsPerPage:number = Math.ceil(pagesStatsBefore.itemsCount/pagesStatsBefore.pagesCount) | 1 ;
        const previousOffset:number = pagesStatsBefore.pageCurrent*itemsPerPage ;

        return new Promise((resolve, reject) => {
            if (documentCode.length) {
                const profil = this.context.rootGetters['account/profil'];

                // Define callbacks
                const removeFromMyFDSSuccess:Function = (response:any) => {
                    const fdsListCache = this.context.getters["FdsListCache"] ;
                    MyFDSState.removeFDSFromList(fdsListCache, documentCode) ;

                    if(response.data.collection.data) {
                        for(const data of response.data.collection.data) {
                            switch (data.name) {
                                case 'total_nb_items' :
                                // If the previous page stats item count is lower than the new value (-1), it means the list is filtered.
                                // In this case, only subtract one from the count
                                    pagesStatsBefore.itemsCount = pagesStatsBefore.itemsCount < data.value-1 ? pagesStatsBefore.itemsCount-1 : data.value;
                                    break;
                                default :
                                    break;
                            }
                        }
                    }
                    this.context.commit('setPagesStats', pagesStatsBefore) ;
                    //TODO: We can't assume that the list will be reduced by only one line. Because removing an FDS with multiple languages will remove more than one line.
                    // This is a bug though. And once solved, only one line should be removed.
                    // Meanwhile, in this case, it will reload the list because it's smaller than expected.
                    this.context.dispatch('getMyFDS', {limit : itemsPerPage-1, offset : previousOffset, filters : this.context.getters["CurrentFilters"]})
                    .finally(() => {
                        resolve(response);
                    })
                }
                const removeFromMyFDSFail:Function = (error:any) => {
                    ErrorService.onDefaultError(error);
                    reject();
                }
                // End define callbacks

                // Run the actual query
                const params:Users_Login_Profile_type_Documents_Document_code_DELETE_APIInterface = {};
                const query:Users_Login_Profile_type_Documents_Document_code_DELETE_APIQueryInterface = {
                    login : profil.login,
                    profile_type : ENUM_PROFILE_TYPE.MYSDS,
                    document_code : documentCode
                };
                const API_class:Users_Login_Profile_type_Documents_Document_code_DELETE_APIClass = new Users_Login_Profile_type_Documents_Document_code_DELETE_APIClass(query, params);
                return API.myFDS.removeDoc(
                        API_class,
                        removeFromMyFDSSuccess,
                        removeFromMyFDSFail
                );
            } else {
                reject();
            }
        });
    }
}
