import Vue from 'vue';
import Router from 'vue-router';
import Store from '@/store/index';

Vue.use(Router);

import { SUPPORTED_LOCALES } from '@/types/Languages'

// Creates regex (en|fr)
function getLocaleRegex() {
    let reg = ''
    SUPPORTED_LOCALES.forEach((locale, index) => {
        reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`
    })
    return `(${reg})`
}
// Returns locale configuration
function getLocale(locale = 'fr') {
    return SUPPORTED_LOCALES.find(loc => loc.code === locale);
}
function load (component:string) {
    return () => import(/* webpackChunkName: '[request]' */ `@/views/${component}/component.vue`)
}

// Avoids having to handle promises with each push call.
const routerPush = Router.prototype.push
Router.prototype.push = function push(location:string) {
    // @ts-ignore
    return routerPush.call(this, location).catch(error=> {})
}

// STATIC
import DefaultLayout from '@/layouts/DefaultLayout/component.vue'
import SimpleLayout from '@/layouts/SimpleLayout/component.vue'
import FdsLayout from '@/layouts/FdsLayout/component.vue'


const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            // for those statics pages, we need to change the scroll position on #links 245px lower
            const target:any = document.getElementById(to.hash.slice(1));
            if(target) {
                return {
                    x: 0,
                    y: target.offsetTop - 40,
                }
            }
        }
        
        if (to.hash && to.hash !== '#') {
            return {selector: to.hash};
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
    routes: [
        // -> public application components
        {
            path: `/:locale${getLocaleRegex()}?`,
            component: DefaultLayout,
            children: [
                {
                    path: '/',
                    alias: ['connect'],
                    component: load('Home'),
                },
                {
                    path: 'search',
                    alias: `search/*`,
                    component: load('SearchFDS'),
                },
                {
                    path: 'my-fds',
                    component: load('MyFDS'),
                },
                {
                    path: 'events',
                    component: load('Events'),
                },
                {
                    path: 'safebox',
                    component: load('Safebox'),
                },
                {
                    path: 'products',
                    component: load('Tools'),
                },
                {
                    path: 'products/vigi-substances',
                    component: load('Tools/VigiSubstances'),
                },
                {
                    path: 'products/fds-extract',
                    component: load('Tools/FdsExtract'),
                },
                {
                    path: 'products/quick-icpe',
                    component: load('Tools/QuickICPE'),
                },
                {
                    path: 'products/quick-adr',
                    component: load('Tools/QuickADR'),
                },
                {
                    path: 'products/fds-retriever',
                    component: load('Tools/FdsRetriever'),
                },
                {
                    path: 'products/fds-labels',
                    component: load('Tools/FdsLabels'),
                },
                {
                    path: 'contact-us',
                    component: load('ContactUs'),
                },
                // old products pages
                {
                    path: 'products_old',
                    alias: ['payment_success', 'resume_payment'],
                    component: load('Tools_old'),
                },
                {
                    path: 'products_old/payment_success',
                    component: load('Tools_old'),
                },
                {
                    path: 'products_old/resume_payment',
                    component: load('Tools_old'),
                },
                {
                    path: 'products_old/vigi-substances',
                    component: load('Tools_old/VigiSubstances'),
                },
                {
                    path: 'products_old/fds-extract',
                    component: load('Tools_old/FdsExtract'),
                },
                {
                    path: 'products_old/quick-icpe',
                    component: load('Tools_old/QuickICPE'),
                },
                {
                    path: 'products_old/quick-adr',
                    component: load('Tools_old/QuickADR'),
                },
                {
                    path: 'products_old/fds-retriever',
                    component: load('Tools_old/FdsRetriever'),
                },
                {
                    path: 'products_old/free-pack',
                    component: load('Tools_old/FreePack'),
                }
            ]
        },
        // -> fds preview with custom layout
        {
            path: `/:locale${getLocaleRegex()}?/fds-preview`,
            alias: `/:locale${getLocaleRegex()}?/test`,
            component: FdsLayout,
            children: [
                {   
                    path: '',
                    alias: `*`,
                    component: load('InfosFDS'),
                }
            ]
        },
        {
            path: `fds-produit/*`,
            redirect: `fds-preview/*`
        },
        
        // -> static application components
        {
            path: `/:locale${getLocaleRegex()}?`,
            component: DefaultLayout,
            children: [
                {
                    path: 'cgu',
                    component: load('static/CGU'),
                },
                {
                    path: 'cgv',
                    component: load('static/CGV'),
                },
                {
                    path: 'legal',
                    component: load('static/LegalNotice'),
                },/*
                {
                    path: 'services/distribution',
                    component: load('static/Distribution'),
                },
                {
                    path: 'services/suppliers-management',
                    component: load('static/SuppliersManagement'),
                },
                {
                    path: 'services/hosting',
                    component: load('static/Hosting'),
                },
                {
                    path: 'consulting',
                    component: load('static/Consulting'),
                }*/
            ]
        },

        // -> account components
        {
            path: `/:locale${getLocaleRegex()}?/account`,
            alias: `/:locale${getLocaleRegex()}?`,
            component: DefaultLayout,
            beforeEnter(to, from, next) {
                if(/profil$/.test(to.path.slice(4)) && !Store.getters['account/loading']) {
                    Store.dispatch('account/getSubscription')
                }
                next();
            },
            children: [
                {
                    path: 'logout',
                    component: load('account/Logout')
                },
                {
                    path: 'profil',
                    component: load('account/Profil'),
                },
                {
                    path: 'forgot-password',
                    component: load('account/ForgotPassword')
                },
                {
                    path: 'change-password/:login/:token',
                    component: load('account/ChangePassword')
                },
                {
                    path: 'change-email',
                    component: load('account/ChangeEmail'),
                },
                {
                    path: 'change-email/:email/:token',
                    component: load('account/ConfirmEmail')
                },
                {
                    path: 'change-clients-codes',
                    component: load('account/ChangeCustomersCodes')
                },
                {
                    path: 'register/confirmation/:token',
                    component: load('account/RegisterConfirmation'),
                },
                {
                    path: `register/confirmation/:token/*`,
                    component: load('account/RegisterConfirmation'),
                },

            ]
        },

        // -> form application components in a simple layout
        {
            path: `/:locale${getLocaleRegex()}?/form`,
            component: SimpleLayout,
            children: [
                {
                    path: 'contact-us',
                    component: load('ContactUs'),
                },
            ]
        },

        {
            path : '*',
            redirect : '/',
        }
    ]
});

// redirect to url with language

router.beforeEach((to:any, from:any, next:any) => {
    /**
     * ADD ALTERNATE LANGUAGE FLAG IN HEAD
     */
    // create a link element
    const makeLink = (name: string) => {
        const link = document.createElement('link');
        link.setAttribute('rel', 'alternate')
        link.setAttribute('hreflang', name)
        link.setAttribute('href', location.origin + (name === 'x-default' ? '/fr' : '/' + name) + to.fullPath.slice(3))    
        link.setAttribute('data-vue-router-controlled', '');
        return link;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el: any) => el.parentNode.removeChild(el));
    
    // Create a link by default
    const defaultLink = makeLink('x-default');
    document.head.appendChild(defaultLink);
    
    // Generate a link for each language
    SUPPORTED_LOCALES.map((language: any) => {
        return makeLink(language.code);
    })
    
    // Add the link tags to the document head.
    .forEach((link: any) => document.head.appendChild(link));


    /**
     * REDIRECTION RULES
     */
    const newTo = {
        path: to.path,
        query: to.query,
        hash: to.hash
    };

    // redirect to events page
    if (to.hash === '#shows-and-events') {
        next('/events');
    // if there is a language set en params
    }
    if (to.params.locale !== undefined) {
        const locale = getLocale(to.params.locale);
        if (locale !== undefined) {
            Store.commit('lang/set', locale.code);
        }
    } else {
        const locale = getLocale(Store.getters['lang/current']);
        const langCode = locale ? locale.code : 'en';
        Store.commit('lang/set', langCode)
        newTo.path = '/' + langCode + to.path;
    }
    // no url other than / ending with "/" to avoid duplicate content
    if (!newTo.path.endsWith('/')) {
        newTo.path += '/'
    }

    if (newTo.path !== to.path) {
        next(newTo);
    } else {
        next();
    }
})

export default router