
import { Vue, Component, Watch} from 'vue-property-decorator'
import ServiceTurnkey from '@/views/static/ServiceTurnkey/component.vue'
import ShowsAndEvents from '@/views/static/ShowsAndEvents/component.vue'
import SiteNavigation from '@/components/navigation/SiteNavigation/component.vue'

// import global libraries
const Promise = require('es6-promise').Promise;
const polyfill = require('es6-promise').polyfill()

//import initiateurs
import {Session_GET_APIInterface, Session_GET_APIQueryInterface} from "@/api/generated/types";
import {Session_GET_APIClass} from "@/api/generated/classes";

// import components
import SplashScreen from '@/components/SplashScreen/component.vue';
import PopinLogin from '@/components/popins/PopinLogin/component.vue';
import PopinError from '@/components/popins/PopinError/component.vue';
import PopinRegisterConfirmation from '@/components/popins/PopinRegisterConfirmation/component.vue';

import axios from "axios";

@Component({
    name: 'fds-layout',
    components: {
        SiteNavigation,
        ServiceTurnkey,
        ShowsAndEvents,
        SplashScreen,
        PopinLogin,
        PopinError,
        PopinRegisterConfirmation
    }
})
export default class FdsLayout extends Vue {
    displayServices(){
        return location.pathname.slice(3) === "/"
    }
    _loading:boolean = false;
    loginKey = 0;
    defaultPopinLogin = 'login';
    freeConsult = false;
    alert = {
        message: "",
        rightTime: false,
        hide: false,
    }

    get codeError() {
        return this.$store.getters['navigation/codeError'];
    }
    get messageError() {
        return this.$store.getters['navigation/messageError'];
    }
    get customError() {
        return this.$store.getters['navigation/customError'];
    }
    get alertMessage() {
        return this.alert.message;
    }
    get cartWaitingForPayment() {
        return this.$store.getters['account/hasSubscriptionPending']
    }
    
    @Watch('codeError')
    @Watch('messageError')
    @Watch('customError')
    showPopinError(error:any) {
        if (error !== null) this.$bvModal.show('popin-error');
    }
    
    @Watch('cartWaitingForPayment')
    showPopinCartWaitingForPayment(waiting:boolean) {
        if (waiting && (this.$route.path.slice(4) !== "products/resume_payment" || this.$route.path.slice(4) !== "products/payment_success")) {
            this.$bvModal.msgBoxConfirm(' Vous étiez presque au bout de votre commande. Voulez vous la reprendre ?', {
                okTitle: this.$t('yes') as string,
                cancelTitle: this.$t('no') as string,
            })
            .then((res)=>{
                if (res) {
                    this.$router.push('/products_old/resume_payment')
                } else if (res === false) {
                    this.$store.dispatch('shopping/cancelPayment')
                            .then(() => {
                                this.$store.dispatch("shopping/clearPending");
                            });
                }
            })
        }
    }

    @Watch('$route', { immediate: true})
    onChangeRoute() {
        if(top && top.frames.length && top.location.href.includes('quickfds.com/') && top.location.href.includes('secure')) {
            // L'administrateur Quick FDS et d'autres applications Quick FDS sont exécutés dans une page avec des frames,
            // lorsque l'utilisateur clic pour revenir au site web grand public on doit afficher ce site dans la page
            // principale et non dans une frame
            top.location.replace(location.href);
            return;
        }
    }

    @Watch('created')
    created() {
        this.setDisplayAlert();
        setInterval(()=>{this.setDisplayAlert(); }, 60000)

        // Listen for new scroll events, here we debounce our `storeScroll` function
        document.addEventListener('scroll', this.debounce(this.storeScroll), { passive: true });
        // Update scroll position for first time
        this.storeScroll();
    }
    @Watch('mounted')
    mounted() {
        if(this.$route.path.includes('/connect')) {
            this.$bvModal.show('popin-login');
        }
    }

    get displayAlert() {
        return this.alert.rightTime && !this.alert.hide;
    }
    get profileIsLocked() {
        return this.$store.getters['navigation/isLocked'].ModifyProfile;
    }

    setDisplayAlert() {
        axios.get("/alert.json")
        .then((res) => {
            if(!this.alert.hide || this.alertMessage !== res.data.message) {
                this.alert.message = res.data.message;
                const start = [...res.data.start];
                start[1]--;
                const end = [...res.data.end];
                end[1]--;
                const dateNow = Date.now();
                //@ts-ignore
                const dateStart = new Date(Date.UTC(...start));
                //@ts-ignore
                const dateEnd = new Date(Date.UTC(...end));
                this.alert.rightTime = Math.floor((dateNow - dateStart.getTime()) / 60000) >= 0 && Math.floor((dateNow - dateEnd.getTime()) / 60000) < 0;
                this.alert.hide = false;
            }
        })
        .catch((err) => {
            if (err.response?.status === 404) {
                // no file alert.json
            }
        })
    }

    displayFreeConsult() {
        this.freeConsult = true;
    }

    closeAlert() {
        this.alert.hide = true;
    }
    scrollTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }
    
    // The debounce function receives our function as a parameter
    debounce = (fn:Function) => {
        // This holds the requestAnimationFrame reference, so we can cancel it if we wish
        let frame:any;
        // The debounce function returns a new function that can receive a variable number of arguments
        return (...params:any[]) => {
            // If the frame variable has been defined, clear it now, and queue for next frame
            if (frame) { 
                cancelAnimationFrame(frame);
            }
            // Queue our function call for the next frame
            frame = requestAnimationFrame(() => {
                // Call our function and pass any params we received
                fn(...params);
            });
        }
    };
  
    // Reads out the scroll position and stores it in the data attribute
    // so we can use it in our stylesheets
    storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY.toString();
    }  
   
    get isLoading () {
        return this.$store.getters['account/loading'];
    }

}

// Register a global custom directive called `v-focus`

Vue.directive('focus', {
    // When the bound element is inserted into the DOM...
    inserted: function (el) {
        const typeTarget = el.classList.contains('search-input') && el.getElementsByTagName('input').length ? 'input' : // if the element as class 'search-input' it will focus the first input childElement
            el.classList.contains('select-item-container') && el.getElementsByTagName('select').length ? 'select' : // if the element as class 'select-item-container' it will focus the first select childElement
            el.classList.contains('list-item-container') && el.getElementsByTagName('button').length ? 'button' : false; // if the element as class 'list-item-container' it will focus the first button childElement
        
        const target = typeTarget ? el.getElementsByTagName(typeTarget)[0] : el;
        target.focus();
        target.classList.add('currentInputInTdr')
    }
})
