
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'


@Component({
    name: 'w-bar-stats'
})
export default class BarStats extends Vue {

    @Prop()
    stats:{value:string, text:string, prefix?:string, suffix?:string}[];


    @Watch('mounted')
    mounted(){
        const texts = document.getElementsByClassName('text');
        for(const statKey in this.stats) {

            const endValue = parseFloat(this.stats[statKey].value.replace(',','.'));
            //@ts-ignore
            this.animateValue(this.$refs[statKey][0].id, 0, endValue, 2000);
        }
    }


    get _stats():{value:string, text:string}[] {
        return this.stats;
    }

    animateValue(id:string, start:number, end:number, duration:number) {
        // assumes integer values for start and end
        const obj = document.getElementById(id);
        const range = end - start;
        // no timer shorter than 50ms (not really visible any way)
        const minTimer = 50;
        // calc step time to show all interediate values
        let stepTime = Math.abs(Math.floor(duration / range));

        // never go below minTimer
        stepTime = Math.max(stepTime, minTimer);

        // get current time and calculate desired end time
        const startTime = new Date().getTime();
        const endTime = startTime + duration;
        let timer:any;

        const run = () => {
            const now = new Date().getTime();
            const remaining = Math.max((endTime - now) / duration, 0);
            const value = remaining > 0 ? Math.round(end - (remaining * range)) : end - (remaining * range);
            //obj.innerHTML = value as any;
            if(obj) {
                obj.innerHTML = new Intl.NumberFormat(this.$lang, {maximumSignificantDigits:10 }).format(value);
            }
            if (value === end) {
                clearInterval(timer);
            }
        }

        timer = setInterval(run, stepTime);
        run();
    }
}
