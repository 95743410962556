export enum TYPES_INPUT {
    SUPPLIER_NAME = 'supplier_name',
    CUSTOMER_CODE = 'customer_code',
    SELECT = 'select_supplier_list',
    LIST = 'list',
    INPUT = 'search_product_form',
    RESULT = 'quick-fds_product',
    START = 'start',
}

export enum ACCESS_LEVELS {
    MIXED = 'mixed',
    PRIVATE = 'private',
    PUBLIC = 'public'
}