import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
interface lockedElement {
    message: string,
    start: number[],
    end: number[]
}

@Module({
    namespaced: true
})
export default class NavigationState extends VuexModule {
    _codeError: any[] | null = null; //If state value cannot be determined, it MUST be initialized with null
    _messageError: string[] | null = null; //If state value cannot be determined, it MUST be initialized with null
    _customError: string | null = null; //If state value cannot be determined, it MUST be initialized with null
    _pageFdsNonExistent: boolean = false;
    _modalShown = {};
    _modalHidden = {};
    _locked:{[key:string]:lockedElement} = {};
    
    get codeError(): string|number[] | null {
        return this._codeError;
    }
    get messageError(): string[] | null {
        return this._messageError;
    }
    get customError(): string | null {
        return this._customError;
    }
    get pageFdsNonExistent(): boolean {
        return this._pageFdsNonExistent;
    }
    get modalShown() {
        return this._modalShown;
    }
    get modalHidden() {
        return this._modalHidden;
    }
    get locked() {
        return this._locked;
    }
    get isLocked() {
        let isLocked:{[key:string]:boolean} = {};
        for (const el in this._locked) {
            const start = [...this._locked[el].start];
            start[1]--;
            const end = [...this._locked[el].end];
            end[1]--;
            const dateNow = Date.now();
            //@ts-ignore
            const dateStart = new Date(Date.UTC(...start));
            //@ts-ignore
            const dateEnd = new Date(Date.UTC(...end));
            isLocked[el] = Math.floor((dateNow - dateStart.getTime()) / 60000) >= 0 && Math.floor((dateNow - dateEnd.getTime()) / 60000) < 0;
        }
        return isLocked;
    }

    @Mutation
    changeCodeError(payload: any[] | string | number) {
        this._codeError = typeof payload === "object" ? payload: [payload];
    }
    @Mutation
    changeMessageError(payload: string[] | string) {
        this._messageError = typeof payload === "object" ? payload: [payload];
    }
    @Mutation
    changeCustomError(payload: string) {
        this._customError = payload;
    }
    @Mutation
    changePageFdsNonExistent(couldntFindAIddc: boolean) {
        this._pageFdsNonExistent = couldntFindAIddc;
    }
    @Mutation
    clearError() {
        this._codeError = null;
        this._messageError = null;
        this._customError = null;
    }
    @Mutation
    changeModalShown(payload: {[key:string]: string}) {
        this._modalShown = {
            ...this._modalShown,
            ...payload
        }
    }
    @Mutation
    changeModalHidden(payload: {[key:string]: string}) {
        this._modalHidden = {
            ...this._modalHidden,
            ...payload
        }
    }
    @Mutation
    addLocked(payload: {[key:string]: lockedElement}) {
        this._locked = {
            ...this._locked,
            ...payload
        }
    }

    @Action
    newErrors(codeErrors: number[]) {
        this.context.commit('changeCodeError', codeErrors);
    }
}
