import store from '@/store/index';
import {ErrorParams, getErrorForParamByType} from "@/api/paramsChecker";

export default class ErrorService {
    constructor() {
        //this.initHandler();
    }

    static onGenericError(error: any) : boolean {
        const response = error.response;
        if (response && response.status === 400) {
            //ErrorService.sentryLogEngine(error);
            if (response.type==="application/json" || !response.data || !response.data.collection || !response.data.collection.errors || !response.data.collection.errors[0] || !response.data.collection.errors[0].code) {
                ErrorService.displayCodeError('h400')
                return false;
            } else if([9999, 595].indexOf(response.data.collection.errors[0].code)>-1) {
                ErrorService.displayCodeError(error.response.data.collection.errors[0].code)
                return false;
            }
        } else if (ErrorService.getCodeError(error) === 556) {
            return false;
        } else {
            ErrorService.displayCodeError("h" + response.status)
            ErrorService.logEngine(error);
            return false;
        }
        return true;
    }
    
    static onDefaultError(error: any) : boolean {
        const error_code = ErrorService.getCodeError(error);
        const error_message = ErrorService.getMessageError(error);
        
        if (error_code === 302) {
            const addrIp = ErrorService.getDataError(error).find((e:any)=>e.name==='ip').value;
            const customError = "<p>Nous enregistrons un fort trafic depuis votre adresse IP ("+addrIp+"). Pour des raisons de sécurité, nous suspendons votre accès aux FDS.<br/>Vous pouvez contacter le service client au +33 (0)1 53 35 07 27 ou par e-mail <a href='mailto:support.client@quickfds.com'>support.client@quickfds.com</a>.</p><p>Pour les autres langues utilisez l'adresse e-mail <a href='mailto:customer.support@quickfds.com'>customer.support@quickfds.com</a>.</p>"
            
            ErrorService.displayCustomError(customError);
            return !!error_code;
        } else if (error_code === 556) {
            const customError = "<p>Votre session a expiré</p>"
            ErrorService.displayCustomError(customError)
            ErrorService.displayCodeError(error_code)
            return false;
        } else if (error_message) {
            ErrorService.displayMessageError(error_message as string);
            return !!error_message;
        } else {
            ErrorService.displayCodeError(error_code as number|string)
            return !!error_code;
        }
    }

    static onFieldError (error: any, input_fields: any, error_input_field : any) : boolean {
        const collection_error: any = error.response.data.collection.errors[0];
        let error_field : string|undefined;
        for (const data_error of collection_error.data) {
            if (data_error.name && (data_error.name === 'f' || data_error.name === 'p')) {
                error_field = data_error.value;
                break;
            }
        }
        if (error_field && error_field in input_fields) {
            error_input_field[error_field] = new ErrorParams ('API', collection_error.code, collection_error.message);
            return true;
        } else {
            return false;
        }
    }

    static getCodeError(error: any):string|number|boolean {
        if (error.response?.data?.collection?.errors[0]) {
            return error.response.data.collection.errors[0].code || false;
        } else {
            return false;
        }
    }
    static getMessageError(error: any):string|boolean {
        if (error.response?.data?.collection?.errors[0]) {
            return error.response.data.collection.errors[0].message || false;
        } else {
            return false;
        }
    }
    static getDataError(error: any):any {
        if (error.response?.data?.collection?.errors[0]) {
            return error.response.data.collection.errors[0].data || false;
        } else {
            return false;
        }
    }

    initHandler() {
        window.onerror = (message, url, lineNo, columnNo, error) => {
            if (error) {
                ErrorService.onGenericError(error);
            }
        };
    }

    static displayCodeError(code: string|number) {
        store.commit('navigation/changeCodeError', code);
    }
    static displayMessageError(message: string) {
        store.commit('navigation/changeMessageError', message);
    }
    static displayCustomError(message: string|boolean) {
        if(!!message)
            store.commit('navigation/changeCustomError', message);
        else
            return false;
    }

    // on s'en fout à priori
    static logEngine(error: any) {
        return error;
    }
}