import axios from 'axios';
import { paramsAreValid } from './paramsChecker';
import {
    Cart_DELETE_APIClass,
    Cart_POST_APIClass, Contact_technical_support_POST_APIClass, Contact_us_POST_APIClass,
    Cart_PUT_APIClass,
    Cart_GET_APIClass,
    Catalog_GET_APIClass,
    Documents_GET_APIClass,
    Document_Details_GET_APIClass,
    Search_steps_GET_APIClass,
    Session_DELETE_APIClass,
    Session_GET_APIClass,
    Session_POST_APIClass,
    Session_PUT_APIClass,
    Users_Creation_request_POST_APIClass,
    Users_Creation_request_code_POST_APIClass,
    Users_Login_Modify_email_request_POST_APIClass,
    Users_Login_MySDS_Documents_Changelog_GET_APIClass,
    Users_Login_MySDS_Documents_Sds2excel_GET_APIClass,
    Users_Login_Command_DELETE_APIClass,
    Users_Login_Command_POST_APIClass,
    Users_Login_Command_GET_APIClass,
    Users_Login_MySDS_Documents_Export_GET_APIClass,
    Users_Login_Profile_type_Customer_codes_DELETE_APIClass,
    Users_Login_Profile_type_Customer_codes_GET_APIClass,
    Users_Login_Profile_type_Customer_codes_POST_APIClass,
    Users_Login_Profile_type_Documents_Document_code_DELETE_APIClass,
    Users_Login_Profile_type_Documents_GET_APIClass,
    Users_Login_Profile_type_Documents_POST_APIClass,
    Users_Login_Profile_type_Documents_Document_code_SelectInfReg_PUT_APIClass,
    Users_Login_Profile_type_History_GET_APIClass, Users_Login_Profile_type_PUT_APIClass,
    Users_Login_PUT_APIClass,
    Users_Login_Safebox_POST_APIClass,
    Users_Login_Billing_POST_APIClass,
    Users_Login_Roles_GET_APIClass,
    Users_Modify_password_request_POST_APIClass,
    Users_POST_APIClass,
    Documents_Verify_customer_code_GET_APIClass,
    Search_next_steps_GET_APIClass,
    Users_Admin_GET_APIClass,
    List_criterias_GET_APIClass,
    Users_Login_Profile_type_Import_consultation_POST_APIClass,
    Report_Data_GET_APIClass,
    Report_Vigi_GET_APIClass,
    Users_Login_Email_PUT_APIClass,
    Users_Subscription_GET_APIClass,
    Request_customer_code_POST_APIClass,
    Documents_Document_code_Preview_GET_APIClass,
    Open_Fds_GET_APIClass
} from "@/api/generated/classes";
import APIClass from "@/api/APIClass";
import { LanguagesEnum } from "@/types/Languages";
import { BlogArticle } from "@/widgets/BlogVignette/types";
import { List_criterias_GET_APIQueryInterface } from './generated/types';
import { Vue } from 'vue-property-decorator';
import ErrorService from "@/services/ErrorService";

let defaultURL = process.env.VUE_APP_API_URL || window.location.origin + '/api/v1/';
let _router: any = {};
let _vueRoot: any = {};


let instance = axios.create({
    baseURL: defaultURL,
    withCredentials: true,
    headers: { 'Accept-Language': 'FR' }
});

// @ts-ignore
const API = {
    init: (vueRoot: Vue, baseURL?: string, router?: any, lang?: string) => {
        defaultURL = baseURL ? baseURL : defaultURL;
        _router = router;
        _vueRoot = vueRoot;
        const headers = lang ? { 'Accept-Language': lang.toUpperCase() } : { 'Accept-Language': 'FR' };

        instance = axios.create({
            baseURL: defaultURL,
            withCredentials: true,
            headers: headers
        });
    },
    logout2: async function () {
        const res: any = await API.callWithClass(
            new Session_DELETE_APIClass(),
            function () {
                localStorage.removeItem('stay_connected_login');
                localStorage.removeItem('stay_connected_pwd');
                _vueRoot.$store.commit('account/logout');
                _vueRoot.$store.commit('shopping/clearCart');
            },
            function (err: any) {
            }
        );
    },
    callWithClass(APIClass: APIClass, success: any, fail: any) {
        const isJsonBlob = (data: any) => data instanceof Blob && data.type === "application/json";

        const callFail: any = async function (error: any) {
            const responseJson: any = error;
            if (error.response && !error.response.headers['content-type'].startsWith('text/html')) {
                // get JSON response from blob response
                const responseData = isJsonBlob(error.response.data) ? await (error.response.data).text() : error.response.data || {};
                responseJson.response.data = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
            }
            fail(responseJson)
            return ErrorService.onGenericError(responseJson);
        }
        // @ts-ignore
        return instance[APIClass.method](
            APIClass.generateURL(),
            APIClass.datas
        )
            .then(success)
            .catch(callFail);
    },
    user: {
        createRequest: (APIClass: Users_Creation_request_POST_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        createRequestCode: (APIClass: Users_Creation_request_code_POST_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        create: (APIClass: Users_POST_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        modify: (APIClass: Users_Login_PUT_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        safebox: (APIClass:Users_Login_Safebox_POST_APIClass , success:any, fail:any) => {
            API.callWithClass(APIClass, success, fail);
        },
        billing: (APIClass:Users_Login_Billing_POST_APIClass , success:any, fail:any) => {
            API.callWithClass(APIClass, success, fail);
        },
        getInfoCommand: (APIClass:Users_Login_Command_GET_APIClass , success:any, fail:any) => {
            API.callWithClass(APIClass, success, fail);
        },
        resumeCommand: (APIClass:Users_Login_Command_POST_APIClass , success:any, fail:any) => {
            API.callWithClass(APIClass, success, fail);
        },
        deleteCommand: (APIClass:Users_Login_Command_DELETE_APIClass , success:any, fail:any) => {
            API.callWithClass(APIClass, success, fail);
        },
        modifyEmail: (APIClass: Users_Login_Email_PUT_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        changePwdRequest: (APIClass: Users_Modify_password_request_POST_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        deleteCustomerCode: (APIClass: Users_Login_Profile_type_Customer_codes_DELETE_APIClass, supplierName: string, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        setCustomerCode: (APIClass: Users_Login_Profile_type_Customer_codes_POST_APIClass, supplierName: string, newVal: string, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        getCustomerCodes: (APIClass: Users_Login_Profile_type_Customer_codes_GET_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        changeEmailRequest: (APIClass: Users_Login_Modify_email_request_POST_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        getInfos: (APIClass: Users_Login_Roles_GET_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        modifyProfile: (APIClass: Users_Login_Profile_type_PUT_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        getAdmin: (APIClass: Users_Admin_GET_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        getSubscription: (APIClass: Users_Subscription_GET_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        }
    },
    session: {
        getDatas: (APIClass: Session_GET_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        login: (APIClass: Session_POST_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        logout: (APIClass: Session_DELETE_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        changeGuestData: (APIClass: Session_PUT_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        }
    },
    consultFDS: {
        urlSupplierName: 'search_steps',
        urlResult: '/documents',
        searchBySupplierName: (APIClass: Search_steps_GET_APIClass, success: any, fail: any) => {
            return API.callWithClass(APIClass, success, fail);
        },
        searchStep: (APIClass: Search_next_steps_GET_APIClass, success: any, fail: any) => {
            return API.callWithClass(APIClass, success, fail);
        },
        searchDocuments: (APIClass: Documents_GET_APIClass, success: any, fail: any) => {
            return API.callWithClass(APIClass, success, fail);
        },
        openFds: (APIClass: Open_Fds_GET_APIClass, success: any, fail: any) => {
            return API.callWithClass(APIClass, success, fail);
        },
        getDocumentPreview: (APIClass: Documents_Document_code_Preview_GET_APIClass, success: any, fail: any) => {
            return API.callWithClass(APIClass, success, fail);
        },
        getFDSDetails: (APIClass:Document_Details_GET_APIClass, success: any, fail: any) => {
            return API.callWithClass(APIClass, success, fail);
        },
        getQRcodeURL: (documentCode: string) => {
            if (paramsAreValid([
                { param: documentCode, type: 'document_code', name: 'documentCode' },
            ])) {
                return defaultURL + '/documents/' + documentCode + '/qrcode';
            }
        },
        getVigiSubstancesURL: (documentCode: string) => {
            if (paramsAreValid([
                { param: documentCode, type: 'document_code', name: 'documentCode' },
            ])) {
                return defaultURL + '/documents/vigi_substances/' + documentCode;
            }
        },
        getDataReportURL: (documentCode: string) => {
            if (paramsAreValid([
                { param: documentCode, type: 'document_code', name: 'documentCode' },
            ])) {
                return defaultURL + '/rapport/donnees_fds/' + documentCode;
            }
        },
        getOtherLanguages: (url: string, success: any, fail: any) => {
            instance.get('/documents' + url.split('/documents')[1])
                .then(success)
                .catch(fail)
        },
        getFDSURL(documentCode: any) {
            if (paramsAreValid([
                { param: documentCode, type: 'document_code', name: 'documentCode' },
            ])) {
                return window.location.origin + '/fds/' + documentCode;
            }
        },
        verifyCustomerCode: (APIClass: Documents_Verify_customer_code_GET_APIClass, success:any, fail :any) => {
            return API.callWithClass(APIClass, success, fail);
        }
    },
    myFDS: {
        getAccessedDocs: (APIClass: Users_Login_Profile_type_History_GET_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        importHistory: (APIClass: Users_Login_Profile_type_Import_consultation_POST_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        removeDoc: (APIClass: Users_Login_Profile_type_Documents_Document_code_DELETE_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        getList(APIClass: Users_Login_Profile_type_Documents_GET_APIClass, success: any, fail: any) {
            API.callWithClass(APIClass, success, fail);
        },
        getChangeLog(APIClass: Users_Login_MySDS_Documents_Changelog_GET_APIClass, success: any, fail: any) {
            API.callWithClass(APIClass, success, fail);
        },
        addDoc: (APIClass: Users_Login_Profile_type_Documents_POST_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        addInfReg: (APIClass: Users_Login_Profile_type_Documents_Document_code_SelectInfReg_PUT_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        exportFds: (APIClass: Users_Login_MySDS_Documents_Export_GET_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        }
    },
    report: {
        getFdsData: (APIClass: Report_Data_GET_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        getVigiSubstances: (APIClass: Report_Vigi_GET_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        }
    },
    shop: {
        getCatalog: (APIClass: Catalog_GET_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        getCart: (APIClass: Cart_GET_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        postCart: (APIClass: Cart_POST_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        putCart: (APIClass: Cart_PUT_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
    },
    forms: {
        contactUs: (APIClass: Contact_us_POST_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        emailUs: (APIClass: Contact_technical_support_POST_APIClass, success: any, fail: any) => {
            API.callWithClass(APIClass, success, fail);
        },
        getCriterias: (APIClass: List_criterias_GET_APIClass, success: any, fail: any) => {
            return API.callWithClass(APIClass, success, fail);
        },
        requestCustomerCode: (APIClass: Request_customer_code_POST_APIClass, success: any, fail: any) => {
            return API.callWithClass(APIClass, success, fail)
        }
    },
    blog: {
        getArticles(lang: LanguagesEnum) {
            return new Promise((resolve, reject) => {
                const jsonLang = require("@/api/blog-fr.json");
                resolve(jsonLang);
            })
        },
    },
    test: {
        getError: (APIClass: Error_GET_APIClass = new Error_GET_APIClass()) => {
            API.callWithClass(APIClass, () => { }, () => { })
        }
    }

}

export class Error_GET_APIClass extends APIClass {
    url: string = "/truc";
}
export default API;