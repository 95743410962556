import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    name: 'w-know-more'
})
export default class wKnowMore extends Vue {
    @Prop()
    inputText?:string;
    @Prop()
    inputProp?:string
    
    get text() {
        return this.inputText||this.$t("+info");
    }
    route() {
        if(this.inputProp) {
            this.$router.push(
                {
                    path: '/contact-us/',
                    query: {
                        questionType: this.inputProp
                    }
                }
            )
        } else {
            this.$router.push('/contact-us/')
        }
    }
}