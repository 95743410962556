
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
    name: 'w-checkbox'
})
export default class Checkbox extends Vue {
    @Prop() value : boolean
    @Prop() tight : boolean

    toggle() {
        this.$emit('input', !this.value)
        this.$emit('change', !this.value)
     }
    get classList() {
        let ret = 'fa';
        ret += this.value ? ' fa-check' : '';
        ret += this.tight ? ' tight' : '';
        return ret;
    }
}
