import { Vue, Component, Prop } from 'vue-property-decorator'


@Component({
    name: 'w-file-input'
})
export default class FileInput extends Vue {
    static instance = 0
    input_id = "w-file-input-" + FileInput.instance++
    files : File[] = []

    @Prop({default: ''}) accept : string
    @Prop({default: false}) multiple : boolean
    @Prop({default: ''}) placeholder : string
    @Prop({default: ''}) text : string

    @Prop({default: undefined}) state ?: boolean
    get stateClassName() {
        return this.state === undefined ? '' : this.state ? 'is-valid' : 'is-invalid'
    }

    onChange(e:any) {
        this.files = e.target.files
        this.$emit('change', this.files)
    }

    get fileList() : string {
        let result = ''
        for (const file of this.files) {
            if (result)
                result += ', '
            result += file.name
        }
        return result
    }

}
