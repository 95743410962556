import { Vue, Component, Prop, Watch } from 'vue-property-decorator'


@Component({
    name: 'w-switch'
})
export default class Select extends Vue {
    @Prop() value : boolean

    get state() : boolean {
        return this.value === undefined ? false : this.value
    }

    toggle() {
        this.$emit('input', !this.value)
        this.$emit('change', !this.value)
    }
}